import { NavLink } from "react-router-dom";
import { TableListBulkSelectControl } from "../../hooks/useTableListBulkSelect";
import { DropdownProps } from "../dropdown";
import TableListItemCols, { TableListItemCol } from "./TableListItemCols";
import { SortableItem, TableListTooltipWrapper } from "./TableListItemHelpers";

export interface Item {
  item_key: string;
  item_active?: boolean;
  item_inactive?: boolean | string;
  item_inactive_blocking?: string;
  isFiller?: boolean;
  onClick?: (item: Item) => void;

  [key: string]: any;
}

/**
 * Table List item
 */
export interface TableListItemProps {
  /** Columns configuration. Flex, width, label, etc. */
  cols?: TableListItemCol[];
  /** Renders a NavLink component based to URL return from itemPath */
  itemPath?: (item: Item) => string | undefined;
  /** Alternative to "itemPath". Renders <a> tag based to URL return from itemLink */
  itemLink?: (item: Item) => string;
  /** Alternative to "itemPath" and "itemLink". Custom click handler for Item */
  onItemClick?: (item: Item) => void;
  /**
   * Renders each Item's quick actions.
   * Requires the "item-actions" component to be enabled.
   * */
  itemActionNodes?: (item: Item) => DropdownProps["items"] | undefined;
  /** Renders each Item's value, but ONLY for when cardView is set to TRUE */
  renderValue?: (item: any, key: string) => any[];

  /** Alternate display mode. Cards instead of plane list. */
  cardView?: boolean;
  /** Whether a <SortableList /> wrapper should be rendered for the TableList to be drag-and-drop sortable. */
  sortable?: boolean;
  /** Control object returned by the TableListBulkSelect hook. If this prop is provided, TableList will render BulkSelect */
  bulkSelectControl?: TableListBulkSelectControl;
  /**
   * ..
   * ..
   * ..
   * ..
   */
  itemIndex: number | null;
  item: Item;
  mini: boolean;
}

const TableListItem = ({
  cols,
  onItemClick,
  itemPath = (singleItem) => {
    return "";
  },
  itemLink,
  itemActionNodes,
  renderValue,
  cardView = false,
  sortable = false,
  bulkSelectControl,
  itemIndex,
  item,
  mini,
}: TableListItemProps) => {
  const itemClassNames = cardView
    ? [
        "tw-group tw-block tw-min-w-[300px] tw-max-w-[450px] tw-relative tw-m-1 tw-p-2 tw-bg-cs-shade-50 tw-rounded tw-flex-1 tw-border tw-border-solid tw-border-cs-shade-200",
      ]
    : [
        "tw-group tw-flex tw-flex-nowrap tw-m-0 tw-p-0 tw-relative tw-border-0 tw-border-b last:tw-border-b-0 tw-border-solid tw-border-cs-shade-50 tw-no-underline tw-min-w-min hover:tw-bg-cs-shade-50",
      ];

  if (!cardView) {
    if (!mini) {
      itemClassNames.push("tw-px-4 tw--mx-4");
    } else {
      itemClassNames.push("tw-px-3 tw--mx-3");
    }
  }
  const activeClassName =
    "tw-bg-cs-shade-50 tw-border-r-2 tw-border-r-tw-cs-accent-color";
  if (item.item_active) {
    itemClassNames.push(activeClassName);
  }
  if (item.item_inactive) {
    itemClassNames.push("tw-opacity-50");
  }

  const tableListItemColsProps = {
    item,
    cols,
    bulkSelectControl,
    itemActionNodes,
    renderValue,
    sortable,
    mini,
    itemIndex,
    cardView,
  };

  if (item.isFiller) {
    return (
      <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
        <div className={itemClassNames.join(" ")}>
          <TableListItemCols {...tableListItemColsProps} />
        </div>
      </TableListTooltipWrapper>
    );
  }
  if (itemPath?.(item) && !sortable) {
    itemClassNames.push("tw-cursor-pointer");
    return (
      <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
        <NavLink
          to={{
            pathname: itemPath(item),
          }}
          role="button"
          tabIndex={0}
          className={itemClassNames.join(" ")}
          activeClassName={`active-navlink-tablelist-tw-helper ${activeClassName}`}
        >
          <TableListItemCols {...tableListItemColsProps} />
        </NavLink>
      </TableListTooltipWrapper>
    );
  }
  if (itemLink?.(item) && !sortable) {
    itemClassNames.push("tw-cursor-pointer");
    return (
      <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
        <a
          href={itemLink(item)}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={0}
          className={itemClassNames.join(" ")}
        >
          <TableListItemCols {...tableListItemColsProps} />
        </a>
      </TableListTooltipWrapper>
    );
  }
  if ((item.onClick || onItemClick) && !sortable) {
    itemClassNames.push("tw-cursor-pointer");
    return (
      <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
        <div
          role="button"
          tabIndex={0}
          className={itemClassNames.join(" ")}
          onClick={(e) => {
            e.preventDefault();
            if (item.onClick) {
              item.onClick(item);
            }
            if (onItemClick) {
              onItemClick(item);
            }
          }}
        >
          <TableListItemCols {...tableListItemColsProps} />
        </div>
      </TableListTooltipWrapper>
    );
  }
  if (sortable) {
    if (itemIndex === null) {
      throw new Error("An error occured");
    }
    return (
      <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
        <SortableItem
          id={item.item_key}
          value={<TableListItemCols {...tableListItemColsProps} />}
          className={`${itemClassNames.join(" ")} ${
            cardView ? `tw-cursor-grab` : `tw-cursor-ns-resize`
          }`}
        />
      </TableListTooltipWrapper>
    );
  }
  return (
    <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
      <div className={itemClassNames.join(" ")}>
        <TableListItemCols {...tableListItemColsProps} />
      </div>
    </TableListTooltipWrapper>
  );
};

export default TableListItem;
