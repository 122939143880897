import {
  GetPropertySingleResponse,
  GetPropertySingleQueryParams,
} from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetPropertySingleResponse,
  GetPropertySingleQueryParams
>((result) => {
  if (result) {
    const propertyContentTags: { type: "PropertyContent"; id?: number }[] = [];
    if (result._embedded.contents) {
      propertyContentTags.push(
        ...result._embedded.contents.map(
          (pContent) =>
            ({
              type: "PropertyContent",
              id: pContent.id,
            } as const)
        )
      );
    }

    // merge the property content tags with the property tag
    return [...propertyContentTags, { type: "Property", id: result.id }];
  }

  return ["Property"];
});

export default providesTags;
