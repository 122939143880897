import {
  FilterFormCols,
  FilterFormColsItem,
} from "@casasoft/styleguide/components/filter-form";
import Select from "@casasoft/styleguide/components/formElements/Select";
import { FilterFormProps } from "components/miscellaneous/FilterForm";
import { useTranslation } from "react-i18next";
import { Categories, PropertyStatuses, Utilities } from "utilities";
import useSubsidiaryFilterFormOptions from "components/miscellaneous/subsidiary/hooks/useSubsidiaryFilterFormOptions";
import { useGetPropertyTagListQuery } from "api/entities/propertyTag";
import { PropertyTagListShape } from "api/entities/propertyTag/endpoints/list/types";
import UserSelectContainer from "components/admin/user/features/user-select/UserSelectContainer";
import { safeJoin } from "utilities/array";

interface UseRenderFilterFormArgs {
  filterFormOptions: FilterFormProps["filterFormOptions"];
}

function useRenderFilterForm({ filterFormOptions }: UseRenderFilterFormArgs): {
  renderFilterForm: FilterFormProps["renderFilterForm"];
} {
  const { t } = useTranslation();
  const subsidiaryOptions = useSubsidiaryFilterFormOptions();

  const getPropertyTagListResult = useGetPropertyTagListQuery(undefined);

  return {
    renderFilterForm: (actions) => {
      const selectedStatuses = actions.getFilterFieldValue("statuses") || [];
      const selectedTags = actions.getFilterFieldValue("tags") || [];
      const selectedSalesPersons =
        actions.getFilterFieldValue("salesPersons") || [];
      const selectedSubsidiaries =
        actions.getFilterFieldValue("subsidiaries") || [];
      return (
        <div>
          <FilterFormCols>
            <FilterFormColsItem span="third">
              <Select
                nobox
                className="tw-mb-0"
                isClearable
                label={filterFormOptions?.preset.marketingMethod.label}
                value={safeJoin(actions.getFilterFieldValue("marketingMethod"))}
                id="marketingMethod"
                onChange={(newSelection) => {
                  actions.onFilterFieldChange(
                    "marketingMethod",
                    newSelection || ""
                  );
                }}
                options={[
                  {
                    value: "rent",
                    label: t("Rent"),
                  },
                  {
                    value: "buy",
                    label: t("Buy"),
                  },
                ]}
              />
            </FilterFormColsItem>
            <FilterFormColsItem span="third">
              <Select
                nobox
                className="tw-mb-0"
                isClearable
                label={filterFormOptions?.preset.statuses.label}
                value={selectedStatuses.join(",")}
                id="statuses"
                isMulti
                onChange={(newSelection) => {
                  actions.onFilterFieldChange(
                    "statuses",
                    newSelection ? newSelection.split(",") : null
                  );
                }}
                options={PropertyStatuses.getPropertyStatus()}
              />
            </FilterFormColsItem>
            {!!getPropertyTagListResult?.data?.propertyTags && (
              <FilterFormColsItem span="third">
                <Select
                  nobox
                  className="tw-mb-0"
                  label={filterFormOptions?.preset.tags.label}
                  value={selectedTags.join(",")}
                  id="tags"
                  isMulti
                  onChange={(newSelection) => {
                    actions.onFilterFieldChange(
                      "tags",
                      newSelection ? newSelection.split(",") : null
                    );
                  }}
                  options={getPropertyTagListResult.data.propertyTags.map(
                    (x: PropertyTagListShape) => {
                      return { value: x.name, label: x.name };
                    }
                  )}
                />
              </FilterFormColsItem>
            )}
            <FilterFormColsItem span="third">
              <UserSelectContainer
                className="tw-mb-0"
                label={
                  filterFormOptions?.preset?.salesPersons?.label
                    ? filterFormOptions.preset.salesPersons.label
                    : ""
                }
                value={
                  !!selectedSalesPersons.length
                    ? selectedSalesPersons.map((username: string) => ({
                        value: username,
                      }))
                    : undefined
                }
                isClearable
                isMulti
                onChange={(newSelection) => {
                  actions.onFilterFieldChange(
                    "salesPersons",
                    newSelection?.length
                      ? newSelection.map((user) => user.value)
                      : null
                  );
                }}
              />
            </FilterFormColsItem>
            {!!subsidiaryOptions && (
              <FilterFormColsItem span="third">
                <Select
                  nobox
                  className="tw-mb-0"
                  isClearable
                  label={filterFormOptions?.preset.subsidiaries.label}
                  value={selectedSubsidiaries.join(",")}
                  id="subsidiaries"
                  isMulti
                  onChange={(newSelection) => {
                    actions.onFilterFieldChange(
                      "subsidiaries",
                      newSelection ? newSelection.split(",") : null
                    );
                  }}
                  options={subsidiaryOptions}
                />
              </FilterFormColsItem>
            )}
            <FilterFormColsItem span="third">
              <div className="tw-grid md:tw-grid-cols-2 tw-gap-4">
                <div className="tw-min-w-0">
                  <Select
                    nobox
                    className="tw-mb-0"
                    label={filterFormOptions?.preset.roomsFrom.label}
                    value={actions.getFilterFieldValue("roomsFrom")?.join()}
                    isClearable
                    id="roomsFrom"
                    onChange={(newSelection) => {
                      actions.onFilterFieldChange("roomsFrom", newSelection);
                    }}
                    options={Array.from(Array(5).keys()).map((x) => {
                      return {
                        label: (x + 1).toString(),
                        value: (x + 1).toString(),
                      };
                    })}
                  />
                </div>
                <div className="tw-min-w-0">
                  <Select
                    nobox
                    className="tw-mb-0"
                    label={filterFormOptions?.preset.roomsTo.label}
                    value={actions.getFilterFieldValue("roomsTo")?.join()}
                    isClearable
                    id="roomsTo"
                    onChange={(newSelection) => {
                      actions.onFilterFieldChange("roomsTo", newSelection);
                    }}
                    options={Array.from(Array(5).keys()).map((x) => {
                      return {
                        label: (x + 2).toString(),
                        value: (x + 2).toString(),
                      };
                    })}
                  />
                </div>
              </div>
            </FilterFormColsItem>
            {filterFormOptions?.preset.utilities &&
              filterFormOptions?.preset.categories && (
                <>
                  <FilterFormColsItem span="third">
                    <Select
                      nobox
                      className="tw-mb-0"
                      isClearable
                      isMulti
                      id="utilities"
                      label={filterFormOptions?.preset.utilities.label}
                      onChange={(newSelection) => {
                        actions.onFilterFieldChange("utilities", newSelection);
                      }}
                      value={actions.getFilterFieldValue("utilities")?.join()}
                      options={Utilities.getUtilities()}
                    />
                  </FilterFormColsItem>
                  <FilterFormColsItem span="third">
                    <Select
                      nobox
                      className="tw-mb-0"
                      isClearable
                      isMulti
                      id="categories"
                      label={filterFormOptions?.preset.categories.label}
                      onChange={(newSelection) => {
                        actions.onFilterFieldChange("categories", newSelection);
                      }}
                      value={actions.getFilterFieldValue("categories")?.join()}
                      options={Categories.getCategories()
                        .sort((a, b) => {
                          if (a.label.toLowerCase() < b.label.toLowerCase())
                            return -1;
                          if (a.label.toLowerCase() > b.label.toLowerCase())
                            return 1;
                          return 0;
                        })
                        .map((elem) => {
                          return {
                            value: elem.value,
                            label: elem.label,
                          };
                        })}
                    />
                  </FilterFormColsItem>
                </>
              )}
          </FilterFormCols>
        </div>
      );
    },
  };
}

export default useRenderFilterForm;
