import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Field from "@casasoft/styleguide/components/formElements/Field";
import NumberField from "@casasoft/styleguide/components/forms/NumberField";
import Switch from "@casasoft/styleguide/components/formElements/Switch";
import DateField from "@casasoft/styleguide/components/formElements/DateField";
import Tinymce from "@casasoft/styleguide/components/formElements/tinymce/Tinymce";
import Select from "@casasoft/styleguide/components/formElements/Select";
import { useTranslation } from "react-i18next";
import csMoment from "utilities/csMoment";
import resourceHelper from "utilities/resource";

import Config from "config";
import resourceGrabber from "utilities/resourceGrabber";
import useEventTypes from "hooks/useEventTypes";
import CreatePhaseRelationsGroup from "components/miscellaneous/pipeline/CreatePhaseRelationsGroup";
import useForm, { RegisterFields } from "hooks/useForm";
import { SubmitHandler } from "react-hook-form";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import Button from "@casasoft/styleguide/components/forms/Button";
import FormGroup from "@casasoft/styleguide/components/formElements/helpers/FormGroup";
import { ErrorMessage } from "@hookform/error-message";
import { Spinner } from "@casasoft/styleguide/components/helpers-ux";
import { EventShape } from "entities/event/types";
import { useDynamicProperty } from "entities/dynamicProperty/store";
import EventContactChooseCard from "./EventContactChooseCard";
import { mainReminderResource } from "entities/event/mainReminderStore";
import { quickReminderResource } from "entities/event/quickReminderStore";
import {
  FormModalCols,
  FormModalColsItem,
} from "@casasoft/styleguide/components/form-modal";
import { axiosInstance } from "utilities/axios";
import handleFormModalError, {
  FormModalOnFail,
} from "@casasoft/styleguide/utilities/api-error/handleFormModalError";
import usePhaseByEvent from "hooks/usePhaseByEvent";
import { useAppSelector } from "redux/hooks";
import { selectIsCrm } from "redux/auth/selectors";
import isMarketingMethod from "utilities/type-helpers/isMarketingMethod";
import UserContactSelectContainer from "components/admin/user/features/user-contact-select/UserContactSelectContainer";

interface FormShape {
  startDate: string | null;
  startTime: string | null;
  duration: number | null;
  subject: string | null;
  tags: string | null;
  user: string | null;
  contact: string | null;
  property: string | null;
  propertyMarketingMethod: string | null;
  html: string | null;
  relationType: string | null;
  relationPhase: string | null;
  eventPhase: string | null;
  eventBaseType: string | null;
  eventType: string | null;
  customSource: string | null;
  offerAmount: string | null;
  private: boolean;
  addReminder: boolean;
  reminderStartDate: string | null;
  reminderStartTime: string | null;
  reminderSubject: string | null;
  reminderHtml: string | null;
  reminderUser: string | null;
}

interface EventCreateFormProps {
  editFormId?: string;
  defaultData?: Partial<FormShape>;

  onDone: (body?: EventShape) => void;
  onFail: FormModalOnFail;
}

const EventCreateForm = ({
  editFormId,
  defaultData = {},
  onDone,
  onFail,
}: EventCreateFormProps) => {
  const { t } = useTranslation();
  const { getSingleItem, getSingleItemLabel, getGroupedEventTypesList } =
    useEventTypes();

  const defaultDataRef = useRef({
    relationType: defaultData.relationType || "prospective-customer",
    user:
      defaultData.user ||
      resourceGrabber.grab("casaoneUser", "auth")?.contact?.id,
    reminderUser:
      defaultData.reminderUser ||
      resourceGrabber.grab("casaoneUser", "auth")?.contact?.id,
    startDate: defaultData.startDate || csMoment().format("YYYY-MM-DD"),
    startTime: defaultData.startTime || csMoment().format("HH:mm"),
    ...defaultData,
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty, isSubmitting, dirtyFields },
    trigger,
    reset,
    getValues,
    getDirtyValues,
  } = useForm<FormShape>({
    defaultValues: {
      startDate: defaultDataRef.current.startDate || null,
      startTime: defaultDataRef.current.startTime || null,
      duration: defaultDataRef.current.duration || null,
      subject: defaultDataRef.current.subject || null,
      tags: defaultDataRef.current.tags || null,
      user: defaultDataRef.current.user || null,
      contact: defaultDataRef.current.contact || null,
      property: defaultDataRef.current.property || null,
      propertyMarketingMethod:
        defaultDataRef.current.propertyMarketingMethod || null,
      html: defaultDataRef.current.html || null,
      relationType: defaultDataRef.current.relationType || null,
      relationPhase: defaultDataRef.current.relationPhase || null,
      eventPhase: defaultDataRef.current.eventPhase || null,
      eventBaseType: defaultDataRef.current.eventBaseType || null,
      eventType: defaultDataRef.current.eventType || null,
      customSource: defaultDataRef.current.customSource || null,
      offerAmount: defaultDataRef.current.offerAmount || null,
      private: defaultDataRef.current.private || false,
      addReminder: defaultDataRef.current.addReminder || false,
      reminderStartDate: defaultDataRef.current.reminderStartDate || null,
      reminderStartTime: defaultDataRef.current.reminderStartTime || null,
      reminderSubject: defaultDataRef.current.reminderSubject || null,
      reminderHtml: defaultDataRef.current.reminderHtml || null,
      reminderUser: defaultDataRef.current.reminderUser || null,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const { getItem: getDynamicProperty } = useDynamicProperty();

  const [currentEventToEdit, setCurrentEventToEdit] = useState<EventShape>();

  useEffect(() => {
    if (editFormId) {
      setIsLoading(true);
      resourceHelper
        .getItem("events", editFormId)
        .then(async (event: EventShape) => {
          const contactPerson =
            event.type === "message-inbound" ||
            event.type === "message-inbound-property-inquiry"
              ? event._embedded?.sender?.id
              : event._embedded?.recipient?.id;

          reset({
            ...defaultDataRef.current,
            eventPhase:
              event?._embedded?.eventPhase === null
                ? undefined
                : event?._embedded?.eventPhase?.id,
            eventBaseType: event.type,
            eventType: event._embedded?.eventType?.id,
            startDate: csMoment(event.start.date).format("YYYY-MM-DD"),
            startTime: csMoment(event.start.date).format("HH:mm"),
            duration: event.end?.date
              ? csMoment(event.end.date).diff(
                  csMoment(event.start.date),
                  "minutes"
                )
              : undefined,
            subject: event.subject,
            tags: event._embedded?.tags?.map((tag) => tag.name).join(","),
            user:
              event.type === "message-inbound" ||
              event.type === "message-inbound-property-inquiry"
                ? event._embedded?.recipient?.id
                : event._embedded?.sender?.id, // for message-inbound events it should be reversed
            contact: contactPerson,
            property: event._embedded?.property?.id, // for message-inbound events it should be reversed
            propertyMarketingMethod: event._embedded?.property?.marketingMethod,
            html: event.html,
            customSource: event.customSource,
            offerAmount: event.offerAmount || null,
            private: event.private || false,
            relationPhase: event?._embedded?.eventPhase?._embedded?.phase?.id,
            relationType:
              event?.requestedRelationType || "prospective-customer",
          });

          setCurrentEventToEdit(event);
          setIsLoading(false);
        });
    } else if (
      defaultDataRef.current.property ||
      defaultDataRef.current.contact
    ) {
      setIsLoading(true);
      const asyncWrapper = async () => {
        const rawProperty = defaultDataRef.current.property
          ? await getDynamicProperty({ id: defaultDataRef.current.property })
          : undefined;

        reset({
          ...defaultDataRef.current,
          property: defaultDataRef.current.property,
          propertyMarketingMethod: rawProperty?.marketingMethod,
          contact: defaultDataRef.current.contact || undefined,
          relationType: "prospective-customer",
        });
        setIsLoading(false);
      };
      asyncWrapper();
    }
  }, [editFormId, reset, defaultDataRef, getDynamicProperty]);

  const eventBaseType = watch("eventBaseType");
  const eventType = watch("eventType");
  const propertyMarketingMethod = watch("propertyMarketingMethod");
  const relationPhase = watch("relationPhase");

  const phaseByEvent = usePhaseByEvent({
    baseType: eventBaseType || undefined,
    eventType: eventType,
    marketingMethod: isMarketingMethod(propertyMarketingMethod)
      ? propertyMarketingMethod
      : undefined,
  });

  const inferredPhase =
    dirtyFields.relationPhase || editFormId
      ? relationPhase
      : phaseByEvent.phaseByEvent;

  const onSubmit: SubmitHandler<FormShape> = async (data) => {
    const dirtyData = getDirtyValues();
    const {
      eventPhase,
      eventBaseType,
      eventType,
      startDate,
      startTime,
      duration,
      tags: tagsString,
      user,
      contact,
      relationType,
      addReminder,
      reminderHtml,
      reminderStartDate,
      reminderStartTime,
      reminderSubject,
      reminderUser,
      relationPhase,
      propertyMarketingMethod, // extract so it wont get patched
      ...dataToPatch
    } = editFormId ? dirtyData : data; // use dirtyData to patch if we are editing, to create use full data

    const startDateTime = editFormId
      ? undefined
      : `${data.startDate} ${data.startTime}`;

    const transformedData = {
      sender:
        data.eventBaseType === "message-inbound" ||
        data.eventBaseType === "message-inbound-property-inquiry"
          ? contact
          : user, // for message-inbound events it should be reversed
      recipient:
        data.eventBaseType === "message-inbound" ||
        data.eventBaseType === "message-inbound-property-inquiry"
          ? user
          : contact, // for message-inbound events it should be reversed
      start:
        dirtyData?.startDate || dirtyData?.startTime
          ? `${dirtyData.startDate || data.startDate} ${
              data.startTime || "00:00"
            }`
          : startDateTime,
      end: duration
        ? csMoment(`${data.startDate} ${data.startTime || "00:00"}`) // take from data, since dirtyData might not contain start data
            .add(duration, "minutes")
            .format("YYYY-MM-DD HH:mm")
        : duration,
      tags: tagsString?.split(",").map((tag) => ({ name: tag })) || tagsString,
      type: eventBaseType,
      eventType: eventType,
      eventPhase:
        inferredPhase !== undefined && inferredPhase !== null // if removed or changed
          ? {
              id: data.eventPhase,
              phase: inferredPhase,
            }
          : undefined,
    };

    try {
      if (editFormId) {
        await resourceHelper.updateItem(
          "events",
          {
            id: editFormId,
            ...dataToPatch,
            ...transformedData,
            property: dataToPatch.property,
          },
          true
        );
        resourceHelper.reloadList("propertyContacts");
        onDone();
      } else {
        const response = await resourceHelper.createItem("events", {
          ...dataToPatch,
          ...transformedData,
          requestedRelationType: relationType,
        });

        // create reminder
        if (addReminder && response.body.id) {
          await mainReminderResource.createItem(
            {
              type: "reminder",
              sender: user,
              recipient: reminderUser,
              start: reminderStartDate
                ? `${reminderStartDate} ${reminderStartTime || "00:00"}`
                : reminderStartDate,
              subject: reminderSubject,
              html: reminderHtml,
              eventTrigger: response.body.id,
            },
            true
          );
          quickReminderResource.reloadList();
        }
        resourceHelper.reloadList("propertyContacts");
        await resourceHelper.reloadList("events");
        onDone(response.body);
      }
      reset(data);
    } catch (error) {
      handleFormModalError(error, onFail);
    }
  };

  // method to adjust duration when start date changes -> technically we have a end date not duration
  const syncDuration = (oldStart: string, newStart: string) => {
    const currDuration = getValues("duration");
    if (currDuration) {
      const oldStartMoment = csMoment(oldStart);
      const newStartMoment = csMoment(newStart);
      const newDuration =
        oldStartMoment.diff(newStartMoment, "minutes") + currDuration;
      setValue("duration", newDuration > 0 ? newDuration : null, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  // fetch event tags -> should be a hook in the future
  const [tagOptions, setTagOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [customSourceOptions, setCustomSourceOptions] = useState<
    { value: string; label: string }[]
  >([]);
  // fetch list
  useEffect(() => {
    // TODO: create a hook for this
    axiosInstance
      .get(`${Config.apiUrl}/${Config.customerKey}/get-distinct-event-tags`)
      .then((response) => {
        if (response.status === 500) {
          throw Error(`${response.status} ${response.data.detail}`);
        }
        return response.data;
      })
      .then((response: any) => {
        // response
        const tagOptions: any[] = response.eventTags;
        setTagOptions(
          tagOptions
            .map((x) => x.name)
            .sort((a, b) => {
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            })
            .map((x) => {
              return { value: x, label: x };
            })
        );
      });
    axiosInstance
      .get(
        `${Config.apiUrl}/${Config.customerKey}/get-distinct-event-customsources`
      )
      .then((response) => {
        if (response.status === 500) {
          throw Error(`${response.status} ${response.data.detail}`);
        }
        return response.data;
      })
      .then((response: any) => {
        // response
        const eventCustomSources: any[] = response.eventCustomSources;
        setCustomSourceOptions(
          eventCustomSources
            .map((x) => x.customSource)
            .sort((a, b) => {
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            })
            .map((x) => {
              return { value: x, label: x };
            })
        );
      });
  }, []);

  const isCrm = useAppSelector(selectIsCrm);

  return (
    <RegisterFields
      control={control}
      fields={{
        startDate: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        startTime: {
          rules: {
            validate: (value) => {
              // allow empty value
              if (!value) {
                return;
              }

              const isValidFormat = /^[0-9]{2}:[0-9]{2}$/.test(value);
              if (!isValidFormat) {
                // looks this weird because of type interferances: https://github.com/react-hook-form/react-hook-form/issues/580#issuecomment-671330600
                const errMsg = t(
                  "Please input according to the requested format"
                );
                return typeof errMsg === "string" ? errMsg : "error";
              }
            },
          },
        },
        duration: {},
        tags: {},
        eventBaseType: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        eventType: {},
        eventPhase: {},
        user: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        subject: {},
        contact: {
          rules: {
            // validate: async value => {
            //   // only running this validation on contact because it will run anyways because the relationgroup component will trigger all validations.
            //   // it's not critical anyways because validation will run on submit at least once
            //   return await validateRelationAsync(value, getValues("property"));
            // },
          },
        },
        property: {},
        propertyMarketingMethod: {},
        relationType: {},
        relationPhase: {},
        html: {},
        customSource: {},
        offerAmount: {},
        private: {},
        addReminder: {},
        reminderStartDate: {
          rules: {
            validate: (value) => {
              if (!value && getValues("addReminder")) {
                // looks this weird because of type interferances: https://github.com/react-hook-form/react-hook-form/issues/580#issuecomment-671330600
                const errMsg = t("Value is required and can't be empty");
                return typeof errMsg === "string" ? errMsg : "error";
              }
            },
          },
        },
        reminderStartTime: {
          rules: {
            validate: (value) => {
              // allow empty value
              if (!value) {
                return;
              }

              const isValidFormat = /^[0-9]{2}:[0-9]{2}$/.test(value);
              if (!isValidFormat) {
                // looks this weird because of type interferances: https://github.com/react-hook-form/react-hook-form/issues/580#issuecomment-671330600
                const errMsg = t(
                  "Please input according to the requested format"
                );
                return typeof errMsg === "string" ? errMsg : "error";
              }
            },
          },
        },
        reminderSubject: {},
        reminderHtml: {},
        reminderUser: {
          rules: {
            validate: (value) => {
              // value is required if addReminder is checked
              if (!value && getValues("addReminder")) {
                // looks this weird because of type interferances: https://github.com/react-hook-form/react-hook-form/issues/580#issuecomment-671330600
                const errMsg = t("Value is required and can't be empty");
                return typeof errMsg === "string" ? errMsg : "error";
              }
            },
          },
        },
      }}
      render={(fieldsRenderer, chainFields) => {
        return (
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={
              isSubmitting || isLoading
                ? { opacity: 0.5, pointerEvents: "none" }
                : {}
            }
          >
            {/* Register otherwise conditional fields because of a useForm bug (all fields that are being `reset` need to be registered because of this bug) */}
            {eventBaseType === "reminder" &&
              chainFields(
                [
                  "private",
                  "customSource",
                  "user",
                  "eventPhase",
                  "eventType",
                  "eventBaseType",
                  "tags",
                  "duration",
                ],
                () => <></>
              )}
            {(isSubmitting || isLoading) && <Spinner />}
            <FormModalCols>
              <FormModalColsItem>
                {eventBaseType !== "reminder" && (
                  <>
                    {fieldsRenderer("user", (formValue, onFormValueChange) => (
                      <UserContactSelectContainer
                        showCasasoftUsers={isCrm}
                        label={t("User")}
                        value={
                          formValue
                            ? {
                                value: formValue,
                              }
                            : undefined
                        }
                        onChange={(value) => {
                          onFormValueChange(value?.value || null);
                        }}
                        message={{
                          type: "error",
                          text: <ErrorMessage errors={errors} name="user" />,
                        }}
                      />
                    ))}
                    {chainFields(
                      ["eventPhase", "eventType", "eventBaseType"],
                      (formValues, formValueChangeHandlers) => (
                        <Select
                          nobox
                          required
                          isClearable
                          label={t("Interaction")}
                          placeholder={t("Choose Action")}
                          value={
                            formValues.eventType ||
                            formValues.eventBaseType ||
                            undefined
                          } // if no eventType is defined, it must be the baseType or undefined
                          options={getGroupedEventTypesList({
                            context: isCrm ? "crm" : "log",
                          })}
                          onChange={(value: string) => {
                            const template = getSingleItem({ value });
                            if (
                              template?.defaultDuration &&
                              !getValues("duration")
                            ) {
                              setValue("duration", template.defaultDuration, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            }
                            // check eventType label vs current label before updating event to set a default subject value
                            const eventType = getValues("eventType");
                            if (
                              !getValues("subject") ||
                              (eventType &&
                                getValues("subject") ===
                                  getSingleItemLabel({
                                    value: eventType,
                                  }))
                            ) {
                              setValue(
                                "subject",
                                getSingleItemLabel({ value }),
                                {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                }
                              );
                            }

                            formValueChangeHandlers.eventBaseType(
                              template?.baseType || null
                            );
                            formValueChangeHandlers.eventType(
                              template?.eventType || null
                            );
                          }}
                          message={{
                            type: "error",
                            text: (
                              <ErrorMessage
                                errors={errors}
                                name="eventBaseType"
                              />
                            ),
                          }}
                          renderOption={(option) => {
                            return (
                              <span>
                                <FontAwesomeIcon
                                  className="tw-mr-2"
                                  fixedWidth
                                  icon={option.icon}
                                />
                                {option.label}
                              </span>
                            );
                          }}
                          renderValue={(option) => {
                            return (
                              <span>
                                <FontAwesomeIcon
                                  className="tw-mr-2"
                                  fixedWidth
                                  icon={option.icon}
                                />
                                {option.label}
                              </span>
                            );
                          }}
                        />
                      )
                    )}
                    {fieldsRenderer("tags", (formValue, onFormValueChange) => (
                      <Select
                        nobox
                        isMulti
                        isCreatable
                        label={t("Tags")}
                        placeholder={t("Tags")}
                        value={formValue || undefined}
                        options={tagOptions}
                        onChange={(value: string) => {
                          onFormValueChange(value || null);
                        }}
                      />
                    ))}
                  </>
                )}
                {fieldsRenderer("subject", (formValue, onFormValueChange) => (
                  <Field
                    nobox
                    label={t("Subject")}
                    value={formValue || undefined}
                    onChange={(value: string) => {
                      onFormValueChange(value);
                    }}
                  />
                ))}
              </FormModalColsItem>
              <FormModalColsItem>
                {eventBaseType !== "reminder" &&
                  fieldsRenderer(
                    "customSource",
                    (formValue, onFormValueChange) => (
                      <Select
                        nobox
                        isClearable
                        isCreatable
                        label={t("Source")}
                        placeholder={t("Source")}
                        value={formValue || undefined}
                        options={customSourceOptions}
                        onChange={(value: string) => {
                          onFormValueChange(value || "");
                        }}
                      />
                    )
                  )}
                {eventBaseType === "offer" &&
                  fieldsRenderer(
                    "offerAmount",
                    (formValue, onFormValueChange) => (
                      <NumberField
                        nobox
                        prefix={t("CHF")}
                        label={t("Offer amount")}
                        value={formValue || undefined}
                        onChange={(value: string) => {
                          onFormValueChange(value || null);
                        }}
                      />
                    )
                  )}
                <FormModalCols>
                  <FormModalColsItem>
                    {fieldsRenderer(
                      "startDate",
                      (formValue, onFormValueChange) => (
                        <DateField
                          nobox
                          label={t("Start")}
                          value={formValue || undefined}
                          onChange={(value: string) => {
                            if (value && formValue) {
                              const oldStart = `${formValue} ${
                                getValues("startTime") || "00:00"
                              }`;
                              const newStart = `${value} ${
                                getValues("startTime") || "00:00"
                              }`;
                              syncDuration(oldStart, newStart);
                            }
                            onFormValueChange(value);
                          }}
                          locale={csMoment().locale()}
                          message={{
                            type: "error",
                            text: (
                              <ErrorMessage errors={errors} name="startDate" />
                            ),
                          }}
                        />
                      )
                    )}
                  </FormModalColsItem>
                  <FormModalColsItem>
                    {fieldsRenderer(
                      "startTime",
                      (formValue, onFormValueChange) => (
                        <Field
                          nobox
                          label={t("Time")}
                          onChange={(value: string) => {
                            if (getValues("startDate")) {
                              const oldStart = `${getValues("startDate")} ${
                                formValue || "00:00"
                              }`;
                              const newStart = `${getValues("startDate")} ${
                                value || "00:00"
                              }`;
                              syncDuration(oldStart, newStart);
                            }
                            onFormValueChange(value?.replace(".", ":"));
                          }}
                          // validate on blur - we are not using the onDelayChange because it has a bug of firing the first time before any input actually happened
                          onBlur={() => {
                            trigger("startTime");
                          }}
                          value={formValue || undefined}
                          placeholder="HH:MM"
                          message={{
                            type: "error",
                            text: (
                              <ErrorMessage errors={errors} name="startTime" />
                            ),
                          }}
                        />
                      )
                    )}
                  </FormModalColsItem>
                </FormModalCols>
                {eventBaseType !== "reminder" && (
                  <>
                    {fieldsRenderer(
                      "duration",
                      (formValue, onFormValueChange) => (
                        <NumberField
                          nobox
                          suffix={t("Minutes")}
                          label={t("Duration")}
                          value={formValue?.toString() || ""}
                          min={0}
                          onChange={(value: string) => {
                            onFormValueChange(
                              value ? parseInt(value, 10) : null
                            );
                          }}
                          quickActions={[
                            {
                              key: "15m",
                              label: "15m",
                              onClick: () => {
                                onFormValueChange(15);
                              },
                            },
                            {
                              key: "30m",
                              label: "30m",
                              onClick: () => {
                                onFormValueChange(30);
                              },
                            },
                            {
                              key: "45m",
                              label: "45m",
                              onClick: () => {
                                onFormValueChange(45);
                              },
                            },
                            {
                              key: "1h",
                              label: `1${t("h")}`,
                              onClick: () => {
                                onFormValueChange(60);
                              },
                            },
                            {
                              key: "2h",
                              label: `2${t("h")}`,
                              onClick: () => {
                                onFormValueChange(120);
                              },
                            },
                            {
                              key: "3h",
                              label: `3${t("h")}`,
                              onClick: () => {
                                onFormValueChange(180);
                              },
                            },
                          ]}
                        />
                      )
                    )}
                    {fieldsRenderer(
                      "private",
                      (formValue, onFormValueChange) => (
                        <Switch
                          label={t("Private")}
                          checked={formValue || false}
                          onToggle={(value: boolean) => {
                            onFormValueChange(value || false);
                          }}
                        />
                      )
                    )}
                  </>
                )}
              </FormModalColsItem>
            </FormModalCols>
            {fieldsRenderer("html", (formValue, onFormValueChange) => (
              <Tinymce
                label={t("Message")}
                value={formValue || undefined}
                placeholder=""
                onChange={(html) => {
                  onFormValueChange(html || null);
                }}
                rows={6}
                tinymceConfig={{
                  menu: {},
                  menubar: false,
                  browser_spellcheck: true,
                  statusbar: false,
                  inline: false,
                  default_link_target: "_blank",
                  link_assume_external_targets: true,
                  target_list: false,
                  rel_list: [{ title: "nofollow", value: "nofollow" }],
                }}
              />
            ))}
            {eventBaseType !== "reminder" &&
              !isLoading &&
              chainFields(
                [
                  "contact",
                  "property",
                  "propertyMarketingMethod",
                  "relationPhase",
                  "relationType",
                ],
                (formValues, formValueChangeHandlers) => (
                  <FormGroup
                    message={{
                      type: "error",
                      text: <ErrorMessage errors={errors} name="contact" />,
                    }}
                  >
                    <CreatePhaseRelationsGroup
                      onChange={async (data) => {
                        formValueChangeHandlers.relationPhase(
                          data.relationPhase || null
                        );

                        // reset relationPhase, since mMethod changed, the existing phase probably is not correct anymore
                        if (
                          data.propertyMarketingMethod !==
                          getValues("propertyMarketingMethod")
                        ) {
                          setValue("relationPhase", null, {
                            shouldDirty: false,
                            shouldValidate: false,
                          });
                        }

                        formValueChangeHandlers.contact(data.contact || null);
                        formValueChangeHandlers.property(data.property || null);
                        formValueChangeHandlers.propertyMarketingMethod(
                          data.propertyMarketingMethod || null
                        );

                        formValueChangeHandlers.relationType(
                          data.relationType || null
                        );
                      }}
                      currentEventToEdit={currentEventToEdit}
                      values={{
                        contact: formValues.contact || undefined,
                        property: formValues.property || undefined,
                        propertyMarketingMethod:
                          formValues.propertyMarketingMethod || undefined,
                        event: {
                          baseType: eventBaseType || undefined,
                          eventType: eventType || undefined,
                        },
                        relationPhase: inferredPhase || undefined,
                        relationType: formValues.relationType || undefined,
                      }}
                    />
                  </FormGroup>
                )
              )}
            {eventBaseType === "reminder" && (
              <>
                {fieldsRenderer("contact", (formValue, onFormValueChange) => (
                  <EventContactChooseCard
                    contactType="user-contacts-only"
                    contactId={formValue || undefined}
                    editingEventId={editFormId}
                    headerTitle={t("User")}
                    chooserHeaderTitle={t("Choose user")}
                    // filter={[
                    //   {
                    //     type: "eq",
                    //     where: "and",
                    //     field: "contactType",
                    //     value: "user",
                    //   },
                    // ]}
                    onChange={(id) => {
                      onFormValueChange(id);
                    }}
                  />
                ))}
              </>
            )}
            {!editFormId && eventBaseType !== "reminder" && (
              <div className="tw-mt-4">
                {fieldsRenderer(
                  "addReminder",
                  (formValue, onFormValueChange) => (
                    <>
                      <Switch
                        label={t("Add reminder")}
                        checked={formValue || false}
                        onToggle={(value: boolean) => {
                          onFormValueChange(value);
                          setValue(
                            "reminderStartDate",
                            csMoment(getValues("startDate"))
                              .add(1, "day")
                              .format("YYYY-MM-DD"),
                            {
                              shouldDirty: true,
                              shouldValidate: true,
                            }
                          );
                          setValue(
                            "reminderStartTime",
                            getValues("startTime"),
                            {
                              shouldDirty: true,
                              shouldValidate: true,
                            }
                          );
                          setValue(
                            "reminderSubject",
                            getValues("subject")
                              ? `${getValues("subject")}`
                              : t("Reminder"),
                            {
                              shouldDirty: true,
                              shouldValidate: true,
                            }
                          );
                        }}
                      />
                      {formValue === true && (
                        <>
                          <FormModalCols>
                            <FormModalColsItem>
                              {fieldsRenderer(
                                "reminderSubject",
                                (formValue, onFormValueChange) => (
                                  <Field
                                    nobox
                                    className="tw-mb-0"
                                    label={t("Subject")}
                                    value={formValue || undefined}
                                    onChange={(value: string) => {
                                      onFormValueChange(value);
                                    }}
                                  />
                                )
                              )}
                            </FormModalColsItem>
                            <FormModalCols>
                              <FormModalColsItem>
                                {fieldsRenderer(
                                  "reminderStartDate",
                                  (formValue, onFormValueChange) => (
                                    <DateField
                                      nobox
                                      className="tw-mb-0"
                                      label={t("Date")}
                                      value={formValue || undefined}
                                      onChange={(value: string) => {
                                        onFormValueChange(value);
                                      }}
                                      rangeStart={csMoment(formValue)
                                        .subtract(1, "day")
                                        .format("YYYY-MM-DD HH:mm:ss")}
                                      locale={csMoment().locale()}
                                      message={{
                                        type: "error",
                                        text: (
                                          <ErrorMessage
                                            errors={errors}
                                            name="reminderStartDate"
                                          />
                                        ),
                                      }}
                                    />
                                  )
                                )}
                              </FormModalColsItem>
                              <FormModalColsItem>
                                {fieldsRenderer(
                                  "reminderStartTime",
                                  (formValue, onFormValueChange) => (
                                    <Field
                                      nobox
                                      className="tw-mb-0"
                                      label={t("Time")}
                                      value={formValue || undefined}
                                      onChange={(value: string) => {
                                        onFormValueChange(
                                          value?.replace(".", ":")
                                        );
                                      }}
                                      // validate on blur - we are not using the onDelayChange because it has a bug of firing the first time before any input actually happened
                                      onBlur={() => {
                                        trigger("reminderStartTime");
                                      }}
                                      message={{
                                        type: "error",
                                        text: (
                                          <ErrorMessage
                                            errors={errors}
                                            name="reminderStartTime"
                                          />
                                        ),
                                      }}
                                      placeholder="HH:MM"
                                    />
                                  )
                                )}
                              </FormModalColsItem>
                            </FormModalCols>
                          </FormModalCols>
                          <div className="tw-mb-4" />
                          {fieldsRenderer(
                            "reminderHtml",
                            (formValue, onFormValueChange) => (
                              <Tinymce
                                id="reminderHtml"
                                label={t("Notes")}
                                value={formValue || undefined}
                                onChange={(value) => {
                                  onFormValueChange(value || null);
                                }}
                                rows={6}
                                tinymceConfig={{
                                  fixed_toolbar_container:
                                    ".tinymceToolbarContainerAddReminder",
                                }}
                              />
                            )
                          )}

                          {fieldsRenderer(
                            "reminderUser",
                            (formValue, onFormValueChange) => (
                              <UserContactSelectContainer
                                showCasasoftUsers={isCrm}
                                label={t("User")}
                                value={
                                  formValue
                                    ? {
                                        value: formValue,
                                      }
                                    : undefined
                                }
                                onChange={(value) => {
                                  onFormValueChange(value?.value || null);
                                }}
                                message={{
                                  type: "error",
                                  text: (
                                    <ErrorMessage
                                      errors={errors}
                                      name="reminderUser"
                                    />
                                  ),
                                }}
                              />
                            )
                          )}
                        </>
                      )}
                    </>
                  )
                )}
              </div>
            )}
            {isDirty && (
              <ModalFooter>
                <Button
                  isPrimary
                  type="submit"
                  buttonValue={editFormId ? t("Save") : t("Create")}
                />
              </ModalFooter>
            )}
          </form>
        );
      }}
    />
  );
};

export default EventCreateForm;
