import CheckboxNew from "../forms/CheckboxNew";
import { Item } from "./TableListItem";

export interface TableListBulkCheckboxProps {
  item: Item;
  checked: boolean;
  onChange: (item: Item) => void;
  disabled?: boolean;
}

export function TableListBulkCheckbox({
  checked,
  item,
  onChange,
  disabled,
}: TableListBulkCheckboxProps) {
  return (
    <label
      className="tw-pl-0 tw-w-8 tw-flex tw-py-3"
      onClick={(event) => {
        // this is needed to prevent events from parents which will causes bugs
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <CheckboxNew
        data-testid={`${item.id}`}
        name={`${item.id}`}
        disabled={disabled}
        checked={checked}
        onToggle={() => {
          onChange(item);
        }}
      />
    </label>
  );
}
