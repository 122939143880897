import { type AlertItem } from "./helpers/models";
import { useOverdueInvoiceWarning } from "./helpers/useOverdueInvoiceWarning";
import { useMaintenanceWarning } from "./helpers/useMaintenanceWarning";
import { useNewMessagingInfo } from "./helpers/useNewMessagingInfo";
import {
  TECHNICAL_ISSUE_KEY,
  useTechnicalIssuesWarning,
} from "./helpers/useTechnicalIssuesWarning";
import { Notification } from "@casasoft/styleguide/components/notification";
import { useCookies } from "react-cookie";
import { getCurrentPlan, PlanTypes } from "utilities/featureFlags";

export const PageNotificationBanners = () => {
  const [cookies] = useCookies();
  const { overdueInvoiceWarning } = useOverdueInvoiceWarning();
  const { maintenanceWarning } = useMaintenanceWarning();
  const { newMessagingInfo } = useNewMessagingInfo();
  const { technicalIssuesWarning } = useTechnicalIssuesWarning({
    textKey: TECHNICAL_ISSUE_KEY.casaone7835,
    isEnabled: getCurrentPlan() === PlanTypes.Pro,
  });

  const alertItems: AlertItem[] = [];

  if (overdueInvoiceWarning) {
    alertItems.push(overdueInvoiceWarning);
  }
  if (maintenanceWarning) {
    alertItems.push(maintenanceWarning);
  }
  if (newMessagingInfo) {
    alertItems.push(newMessagingInfo);
  }
  if (technicalIssuesWarning) {
    alertItems.push(technicalIssuesWarning);
  }

  const filteredAlertItems = alertItems.filter(
    (alert) => cookies[alert.cookieId] !== "1"
  );
  if (!filteredAlertItems) {
    return null;
  }

  return (
    <div className="tw-flex-shrink-0 tw-flex-grow-0">
      {filteredAlertItems.map((alert) => (
        <Notification
          key={alert.cookieId}
          colorType={alert.colorType}
          cookieId={alert.cookieId}
          text={alert.text}
          onClick={alert.onClick}
        />
      ))}
    </div>
  );
};
