import { useTranslation } from "react-i18next";
import { type AlertItem } from "./models";
import type {
  CookieType,
  NotificationColor,
} from "@casasoft/styleguide/components/notification/models";

interface TechIssue {
  key: string;
  options?: Record<string, string>;
}

interface UseTechnicalIssuesWarningProps {
  textKey: TechIssue;
  isEnabled: boolean;
  colorType?: NotificationColor;
  cookieId?: CookieType;
}

export const TECHNICAL_ISSUE_KEY: Record<string, TechIssue> = {
  casaone7835: {
    key: "casaone-7835",
    options: { supportemail: "support@casasoft.ch" },
  },
  homegate_immoscout24: {
    key: "Homegate and ImmoScout24 technical issues warning",
  },
  phone_issues_29_1: {
    key: "We apologize for the inconvenience, but our telephone system is currently facing technical issues. We are working on this with our provider. Please contact us by email at {{supportemail}}.",
    options: { supportemail: "support@casasoft.ch" },
  },
  phone_issues_30_1: {
    key: "We apologize for the inconvenience, but our telephone system is currently experiencing technical issues. We are working with our provider to resolve this. Please contact us via email at {{supportemail}} or on this provisional number: {{supportphone}}.",
    options: {
      supportemail: "support@casasoft.ch",
      supportphone: "+41 43 508 24 81",
      keySeparator: ">",
      nsSeparator: "|",
    },
  },
} as const;

export const useTechnicalIssuesWarning = ({
  textKey,
  isEnabled,
  colorType = "warning",
  cookieId = "cs_technical_issues_warning",
}: UseTechnicalIssuesWarningProps) => {
  const { t } = useTranslation();

  const technicalIssuesWarning: AlertItem | undefined = isEnabled
    ? {
        colorType,
        cookieId,
        text: t(textKey.key, textKey.options),
      }
    : undefined;

  return {
    technicalIssuesWarning,
  };
};
