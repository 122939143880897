import { CSSProperties, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort as faSortLight } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "../tooltip";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function DragHandle({
  nr = null,
  cardView,
}: {
  nr: number | null;
  cardView: boolean;
}) {
  return (
    <div
      key="sortable-handle"
      className={`tw-absolute ${
        !cardView
          ? "tw-right-4 tw-top-2/4 tw--translate-y-2/4"
          : "tw-bottom-2 tw-right-2"
      } tw-whitespace-nowrap tw-text-cs-shade-500`}
    >
      <span>{nr === null ? "" : nr + 1}</span>
      &nbsp;
      <FontAwesomeIcon fixedWidth icon={faSortLight} />
    </div>
  );
}

export function SortableItem({
  value,
  className = "",
  id,
}: {
  value: ReactNode;
  className: string;
  id: number | string;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "none",
  };
  return (
    <div
      className={className}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {value}
    </div>
  );
}

interface TableListTooltipWrapperProps {
  children: ReactNode;
  invalidated?: string | true;
}

export function TableListTooltipWrapper({
  children,
  invalidated,
}: TableListTooltipWrapperProps) {
  return (
    <Tooltip
      content={!!invalidated && invalidated !== true && invalidated}
      triggerAsChild
    >
      {children}
    </Tooltip>
  );
}
