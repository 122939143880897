import { useTranslation } from "react-i18next";
import { type AlertItem } from "./models";

export const useNewMessagingInfo = () => {
  const { t } = useTranslation();
  const activeUntil = new Date("2024-12-31"); // 1 AM GMT time
  const temporarilyDisabled = false; // emergency switch to old version, reactivate when new version is on again

  const newMessagingInfo: AlertItem | undefined =
    !temporarilyDisabled && activeUntil > new Date()
      ? {
          colorType: "info",
          cookieId: "cs_new_messaging_info",
          text: t("cap-611"),
        }
      : undefined;

  return {
    newMessagingInfo,
  };
};
