import { ContactSelectValueType } from "components/contact/features/contact-select/ContactSelectContainer";

export interface EmailContact {
  email: string;
}

interface Attendee {
  contact: string | EmailContact;
  private?: boolean;
}

export const NEW_DELIMITER = "new:";

const mapContactToAttendee = (
  contact: string,
  isPrivate = false
): Attendee => ({
  contact: getContactId(contact),
  private: isPrivate,
});

export const getNewContactEmail = (contact: string): EmailContact | undefined =>
  contact.includes(NEW_DELIMITER)
    ? { email: contact.split(":")[1] }
    : undefined;

export const getContactId = (contact: string): string | EmailContact =>
  getNewContactEmail(contact) || contact;

export const getAttendees = (
  contactCC: readonly ContactSelectValueType[] = [],
  contactBCC: readonly ContactSelectValueType[] = []
): Attendee[] => [
  ...contactCC.map((contact) => mapContactToAttendee(contact.value)),
  ...contactBCC.map((contact) => mapContactToAttendee(contact.value, true)),
];

export const contactListShouldReload = (
  contact: readonly ContactSelectValueType[] = [],
  contactCC: readonly ContactSelectValueType[] = [],
  contactBCC: readonly ContactSelectValueType[] = []
): boolean =>
  [...contact, ...contactCC, ...contactBCC].some((c) =>
    c.value.includes(NEW_DELIMITER)
  );
