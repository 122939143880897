import { featuresList, legacyFeaturesList } from "./featuresList";
import i18n from "./i18n";
import resourceGrabber from "./resourceGrabber";

type FeatureItem = ReturnType<typeof getFeatures>[number];

type Sort = "a-z";

export function getFeatures(sort: Sort = "a-z") {
  /** TODO:
   * - remove feature-flag
   * - also look at PropertyEnergyCard.test.tsx
   */
  const isDev =
    resourceGrabber.grab("profile", "auth")?.data?.company?.id === "dev";
  const features = isDev ? featuresList : legacyFeaturesList;
  // feature-flag end

  const featuresListMapped = Object.entries(features).map(
    ([key, [i18nKey, group]]) => ({
      key,
      label: i18n.t(i18nKey),
      group,
    })
  );

  if (sort === "a-z") {
    return [...featuresListMapped].sort((a, b) => {
      return a.label
        .toLowerCase()
        .toString()
        .localeCompare(b.label.toLowerCase());
    });
  }
  return [...featuresListMapped];
}
export function getFeatureGroup<TGroup extends FeatureItem["group"]>(
  group: TGroup,
  sort: Sort = "a-z"
) {
  return getFeatures(sort).filter((elem) => elem.group === group);
}

export function featureFromTemplate<TKey extends string>(key?: TKey) {
  const features = getFeatures();

  const featureTemplate = features.find((elem) => elem.key === key);

  if (!featureTemplate) {
    throw new Error(`Unknown feature key: ${key}`);
  }
  return featureTemplate as Extract<FeatureItem, { key: TKey }>;
}

export function getFeatureLabel<TKey extends string>(key: TKey) {
  return featureFromTemplate(key).label;
}

export function exists(key: string, group: FeatureItem["group"]) {
  const featuresFromGroup = getFeatureGroup(group);
  return featuresFromGroup.findIndex((elem) => elem.key === key) !== -1;
}

export function getLabelListFromEntity(
  entity?: {
    _embedded?: {
      features?: { slug: string }[];
    };
  },
  sep = ", "
) {
  return (
    entity?._embedded?.features
      ?.map((elem) => getFeatureLabel(elem.slug))
      .join(sep) || ""
  );
}
