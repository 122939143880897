import { useIaziGetCommonDataQuery } from "api/entities/iazi";
import type { IaziCommonDataRequestBody } from "api/entities/iazi/endpoints/get-common-data/types";
import { useEffect } from "react";

interface UseIaziDataProps {
  address?: Partial<IaziCommonDataRequestBody>;
  onError?: () => void;
  onEmptyFetch?: () => void;
  iaziEnabled?: boolean;
}

export const useIaziData = ({
  address,
  onEmptyFetch,
  onError,
  iaziEnabled = false,
}: UseIaziDataProps) => {
  const result = useIaziGetCommonDataQuery(
    { ...address },
    { skip: !iaziEnabled }
  );

  useEffect(() => {
    if (result.currentData?.properties?.length === 0) {
      onEmptyFetch?.();
    }
  }, [onEmptyFetch, result.currentData]);

  useEffect(() => {
    if (result.error) {
      onError?.();
    }
  }, [onError, result.error]);

  return {
    lastDataCall: {
      isFetching: result.isFetching,
      noResults:
        result.isSuccess && result.currentData?.properties?.length === 0,
      error: result.error,
    },
    iaziData: result.currentData?.properties || [],
  };
};
