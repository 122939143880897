export const categoriesList: [string, string][] = [
  ["apartment", "standards:Apartment"],
  ["attic-flat", "standards:Attic flat"],
  ["bifamiliar-house", "standards:Bifamiliar house"],
  ["building-land", "standards:Building land"],
  ["double-garage", "standards:Double garage"],
  ["duplex", "standards:Duplex"],
  ["factory", "standards:Factory"],
  ["farm-house", "standards:Farm house"],
  ["furnished-flat", "standards:Furnished flat"],
  ["garage", "standards:Garage"],
  ["house", "standards:House"],
  ["loft", "standards:Loft"],
  ["mountain-farm", "standards:Mountain farm"],
  ["multiple-dwelling", "standards:Multiple dwelling"],
  ["open-slot", "standards:Open slot"],
  ["parking-space", "standards:Parking space"],
  ["plot", "standards:Plot"],
  ["roof-flat", "standards:Roof flat"],
  ["row-house", "standards:Row house"],
  ["single-garage", "standards:Single garage"],
  ["single-house", "standards:Single house"],
  ["single-room", "standards:Single room"],
  ["terrace-flat", "standards:Terrace flat"],
  ["terrace-house", "standards:Terrace house"],
  ["underground-slot", "standards:Underground slot"],
  ["villa", "standards:Villa"],
  ["chalet", "standards:Chalet"],
  ["studio", "standards:Studio"],
  ["covered-slot", "standards:Covered slot"],
  ["office", "standards:Office"],
  ["flat", "standards:Flat"],
  ["exhibition-space", "standards:Exihition space"],
  ["retail", "standards:Retail"],
  ["bed-and-breakfast", "standards:Bed and breakfast"],
  ["hotel", "standards:Hotel"],
  ["warehouse", "standards:Warehouse"],
  ["workshop", "standards:Workshop"],
  ["car-park", "standards:Car park"],
  ["bungalow", "standards:Bungalow"],
  ["castle", "standards:Castle"],
  ["fuel-station", "standards:Fuel station"],
  ["cafe-bar", "standards:Café / Bar"],
  ["garden-apartment", "standards:Garden Apartment"],
  ["maisonette", "standards:Maisonette"],
  ["rustico", "standards:Rustico"],
  ["retail-space", "standards:Retail space"],
  ["boat-dry-dock", "standards:Boat dry dock"],
  ["alottmen-garden", "standards:Alottment garden"],
  ["squash-badminton", "standards:Squash / Badminton"],
  ["indoor-tennis-court", "standards:Indoor tennis court"],
  ["tennis-court", "standards:Tennis court"],
  ["sports-hall", "standards:Sports hall"],
  ["campground", "standards:Campground / Tent camping"],
  ["outdoor-swimming-pool", "standards:Outdoor swimming pool"],
  ["indoor-swimming-pool", "standards:Indoor swimming pool"],
  ["golf-course", "standards:Golf course"],
  ["hospital", "standards:Hospital"],
  ["mini-golf-course", "standards:Mini-golf course"],
  ["nursing-home", "standards:Nursing home"],
  ["riding-hall", "standards:Riding hall"],
  ["sanatorium", "standards:Sanatorium"],
  ["sauna", "standards:Sauna"],
  ["solarium", "standards:Solarium"],
  ["old-age-home", "standards:Old-age home"],
  ["display-window", "standards:Display window"],
  ["granny-flat", "standards:Granny flat"],
  ["boat-landing-stage", "standards:Boat landing stage"],
  ["horse-box", "standards:Horse box"],
  ["boat-mooring", "standards:Boat mooring"],
  ["cellar-compartment", "standards:Cellar compartment"],
  ["attic-compartment", "standards:Attic compartment"],
  ["house-part", "standards:Part of a house"],
  [
    "residential-commercial-building",
    "standards:Residential / commercial building",
  ],
  ["engadine-house", "standards:Engadine house"],
  ["patrician-house", "standards:Patrician house"],
  ["hobby-room", "standards:Atelier"],
  ["restaurant", "standards:Restaurant"],
  ["agricultural-lot", "standards:Agricultural land"],
  ["arcade", "standards:Arcade"],
  ["bakery", "standards:Bakery"],
  ["butcher", "standards:Butcher"],
  ["car-repair-shop", "standards:Car repair shop"],
  ["carpentry-shop", "standards:Carpentry shop"],
  ["casino", "standards:Casino"],
  ["cheese-factory", "standards:Cheese factory"],
  ["club-disco", "standards:Club / Disco"],
  ["commercial-lot", "standards:Commercial building plot"],
  ["commercial-space", "standards:Commercial premises"],
  ["doctors-office", "standards:Doctors office"],
  ["earth-sheltered-dwelling", "standards:Earth sheltered dwelling"],
  ["hairdresser", "standards:Hair salon"],
  ["industrial-lot", "standards:Industrial building plot"],
  ["industrial-object", "standards:Industrial property"],
  ["kiosk", "standards:Kiosk"],
  ["laboratory", "standards:Laboratory"],
  ["library", "standards:Library"],
  ["market-garden", "standards:Market garden"],
  ["motel", "standards:Motel"],
  ["movie-theater", "standards:Movie theatre"],
  ["multistorey-car-park", "standards:Multistorey car park"],
  ["orphanage", "standards:Orphanage"],
  ["party-room", "standards:Party room"],
  ["pub", "standards:Pub"],
  ["stoeckli", "standards:Stoeckli"],
  ["shopping-center", "standards:Shopping center"],
  ["storage-room", "standards:Storage room"],
];

// TODO: remove the following when removing feature-flag
export const legacyCategoriesList: [string, string][] = [
  ["apartment", "standards:Apartment"],
  ["attic-flat", "standards:Attic flat"],
  ["bifamiliar-house", "standards:Bifamiliar house"],
  ["building-land", "standards:Building land"],
  ["double-garage", "standards:Double garage"],
  ["duplex", "standards:Duplex"],
  ["factory", "standards:Factory"],
  ["farm-house", "standards:Farm house"],
  ["furnished-flat", "standards:Furnished flat"],
  ["garage", "standards:Garage"],
  ["house", "standards:House"],
  ["loft", "standards:Loft"],
  ["mountain-farm", "standards:Mountain farm"],
  ["multiple-dwelling", "standards:Multiple dwelling"],
  ["open-slot", "standards:Open slot"],
  ["parking-space", "standards:Parking space"],
  ["plot", "standards:Plot"],
  ["roof-flat", "standards:Roof flat"],
  ["row-house", "standards:Row house"],
  ["single-garage", "standards:Single garage"],
  ["single-house", "standards:Single house"],
  ["single-room", "standards:Single room"],
  ["terrace-flat", "standards:Terrace flat"],
  ["terrace-house", "standards:Terrace house"],
  ["underground-slot", "standards:Underground slot"],
  ["villa", "standards:Villa"],
  ["chalet", "standards:Chalet"],
  ["studio", "standards:Studio"],
  ["covered-slot", "standards:Covered slot"],
  ["office", "standards:Office"],
  ["flat", "standards:Flat"],
  ["exhibition-space", "standards:Exihition space"],
  ["retail", "standards:Retail"],
  ["bed-and-breakfast", "standards:Bed and breakfast"],
  ["hotel", "standards:Hotel"],
  ["warehouse", "standards:Warehouse"],
  ["workshop", "standards:Workshop"],
  ["car-park", "standards:Car park"],
  ["bungalow", "standards:Bungalow"],
  ["castle", "standards:Castle"],
  ["fuel-station", "standards:Fuel station"],
  ["cafe-bar", "standards:Café / Bar"],
  ["garden-apartment", "standards:Garden Apartment"],
  ["maisonette", "standards:Maisonette"],
  ["rustico", "standards:Rustico"],
  ["retail-space", "standards:Retail space"],
  ["boat-dry-dock", "standards:Boat dry dock"],
  ["alottmen-garden", "standards:Alottment garden"],
  ["squash-badminton", "standards:Squash / Badminton"],
  ["indoor-tennis-court", "standards:Indoor tennis court"],
  ["tennis-court", "standards:Tennis court"],
  ["sports-hall", "standards:Sports hall"],
  ["campground", "standards:Campground / Tent camping"],
  ["outdoor-swimming-pool", "standards:Outdoor swimming pool"],
  ["indoor-swimming-pool", "standards:Indoor swimming pool"],
  ["golf-course", "standards:Golf course"],
  ["hospital", "standards:Hospital"],
  ["mini-golf-course", "standards:Mini-golf course"],
  ["nursing-home", "standards:Nursing home"],
  ["riding-hall", "standards:Riding hall"],
  ["sanatorium", "standards:Sanatorium"],
  ["sauna", "standards:Sauna"],
  ["solarium", "standards:Solarium"],
  ["old-age-home", "standards:Old-age home"],
  ["home", "standards:Home"],
  ["display-window", "standards:Display window"],
  ["granny-flat", "standards:Granny flat"],
  ["boat-landing-stage", "standards:Boat landing stage"],
  ["horse-box", "standards:Horse box"],
  ["boat-mooring", "standards:Boat mooring"],
  ["cellar-compartment", "standards:Cellar compartment"],
  ["attic-compartment", "standards:Attic compartment"],
  ["house-part", "standards:Part of a house"],
  [
    "residential-commercial-building",
    "standards:Residential / commercial building",
  ],
  ["engadine-house", "standards:Engadine house"],
  ["patrician-house", "standards:Patrician house"],
  ["hobby-room", "standards:Atelier"],
  ["restaurant", "standards:Restaurant"],
];
