import Select from "@casasoft/styleguide/components/formElements/Select";
import Switch from "@casasoft/styleguide/components/formElements/Switch";
import { useTranslation } from "react-i18next";
import type {
  IaziCommonDataProperty,
  IaziCommonDataRequestBody,
} from "api/entities/iazi/endpoints/get-common-data/types";
import { useIaziPropertyOptions } from "./hooks/useIaziPropertyOptions";
import { useMemo } from "react";
import { IaziNoResultsWarning } from "./IaziNoResultsWarning";
import { IaziDataError } from "./IaziDataError";

export interface IaziPropertySettingsProps {
  value?: IaziCommonDataProperty;
  onChange: (value: IaziCommonDataProperty | undefined) => void;
  address?: Partial<IaziCommonDataRequestBody>;
}

// TODO: POC implementation, needs to be updated once BE is ready
export const IaziPropertySettings = ({
  value,
  onChange,
  address,
}: IaziPropertySettingsProps) => {
  const { t } = useTranslation();
  const {
    iaziPropertyOptions,
    iaziSelectValue,
    handleSelectIaziOption,
    handleIaziToggle,
    iaziEnabled,
    lastDataCall: { isFetching, error: showError, noResults },
  } = useIaziPropertyOptions({ onChange, value, address });

  const showPropertyOptions = useMemo(
    () => iaziEnabled && !noResults,
    [iaziEnabled, noResults]
  );

  const showNoResultsWarning = noResults && !isFetching;
  const showDescription = !showNoResultsWarning && !showError;

  return (
    <div className="tw-mb-4">
      <div className="tw-border-0 tw-border-b tw-border-solid tw-border-b-tw-cs-accent-color">
        {t("IAZI/CIFI AI Service")}
      </div>
      <Switch
        checked={iaziEnabled}
        label={t("Create property with IAZI/CIFI AI Service")}
        onToggle={handleIaziToggle}
        className="tw-mt-2 tw-ml-[-6px] tw-mb-2"
      />
      {showDescription && (
        <p className="tw-text-sm tw-text-cs-shade-700">
          {t(
            "Create a property supported by AI from IAZI and select from matching results to progress."
          )}
        </p>
      )}
      {showPropertyOptions && (
        <Select
          nobox
          label={t("Choose property")} // TODO: Get the correct label from PO or UX and add to translations
          value={iaziSelectValue}
          options={iaziPropertyOptions}
          onChange={handleSelectIaziOption}
          className="tw-basis-1/2"
          disabled={!iaziPropertyOptions.length}
          key={`${address?.lat}${address?.lon}`}
        />
      )}
      {showNoResultsWarning && <IaziNoResultsWarning />}
      {showError && <IaziDataError />}
    </div>
  );
};
