import { useEffect, useState } from "react";
import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import csMoment from "utilities/csMoment";
import resourceGrabber from "utilities/resourceGrabber";
import baseEventCreator, { EventResourceTypes } from "./baseEventCreator";

const name = "notification" as const;
const pluralName = "notifications" as const;

export const { types, actions, rootReducer }: EventResourceTypes =
  createResource(
    baseEventCreator({
      name,
      pluralName,
      apiContext: "notification-list",
      forcedPageSize: 1, // we only need "total_items" number from response for notifications counter
    })
  );

const notificationResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const notificationResource = notificationResourceCreator.resource;
const useNotification = notificationResourceCreator.createHook();

export function useNotificationsCounter() {
  const { store } = useNotification({
    fetchList: {
      filter: [
        {
          type: "orx",
          conditions: [
            {
              type: "andx",
              conditions: [
                {
                  type: "in",
                  field: "type",
                  values: ["alert"],
                },
                {
                  type: "in",
                  field: "status",
                  values: ["fresh"],
                  where: "and",
                },
              ],
            },
            {
              type: "andx",
              conditions: [
                {
                  type: "eq",
                  field: "type",
                  value: "reminder",
                },
                {
                  type: "eq",
                  field: "recipient",
                  value:
                    resourceGrabber.grab("casaoneUser", "auth")?.contact?.id ||
                    "user-has-no-contact-defined",
                },
                {
                  type: "in",
                  field: "status",
                  values: ["fresh"],
                  where: "and",
                },
                {
                  type: "between",
                  field: "start",
                  from: csMoment()
                    .subtract(30, "day")
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm:59"),
                  to: csMoment()
                    .add(30, "day")
                    .endOf("day")
                    .format("YYYY-MM-DD HH:mm:59"),
                  where: "and",
                },
              ],
            },
          ],
        },
      ],
    },
  });

  // only get count once fetch is complete
  const [cachedAlertCount, setCachedAlertCount] = useState(
    store.listMetadata?.totalItems || 0
  );
  useEffect(() => {
    if (!store.isFetching) {
      setCachedAlertCount(store.listMetadata?.totalItems || 0);
    }
  }, [store.listMetadata?.totalItems, store.isFetching]);

  return cachedAlertCount;
}
