import { type CleanSelectOption } from "@casasoft/styleguide/components/formElements/Select";
import { useMemo, useState } from "react";
import { useIaziData } from "./useIaziData";
import type { IaziPropertySettingsProps } from "../IaziPropertySettings";
import { useIaziDataMapper } from "./useIaziDataMapper";

interface UseIaziPropertyOptionsProps extends IaziPropertySettingsProps {}

export const useIaziPropertyOptions = ({
  address,
  onChange,
  value,
}: UseIaziPropertyOptionsProps) => {
  const [iaziEnabled, setIaziEnabled] = useState(false);
  const clearSelection = () => {
    onChange(undefined);
  };
  const { iaziData, lastDataCall } = useIaziData({
    address,
    iaziEnabled,
    onEmptyFetch: clearSelection,
    onError: clearSelection,
  });
  const { mapIaziDataToOptions } = useIaziDataMapper();

  const iaziPropertyOptions: CleanSelectOption[] = useMemo(
    () => mapIaziDataToOptions(iaziData),
    [iaziData, mapIaziDataToOptions]
  );

  const handleSelectIaziOption = (val: string) => {
    const selectedOption = iaziData.find((option) => option.uid === val);

    if (selectedOption) {
      onChange(selectedOption);
    }
  };

  const iaziSelectValue: string | undefined = useMemo(() => {
    const foundOption = iaziData.find((option) => option.uid === value?.uid);

    return foundOption?.uid;
  }, [iaziData, value]);

  const handleIaziToggle = async (checked: boolean) => {
    setIaziEnabled(checked);
    if (!checked) {
      clearSelection();
    }
  };

  return {
    iaziPropertyOptions,
    handleSelectIaziOption,
    iaziSelectValue,
    handleIaziToggle,
    iaziEnabled,
    lastDataCall,
  };
};
