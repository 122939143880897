import { Card, CardHeader } from "@casasoft/styleguide/components/card";
import { useTranslation } from "react-i18next";
import { faGripHorizontal } from "@fortawesome/pro-light-svg-icons";
import { TableList } from "@casasoft/styleguide/components/table-list";
import { ReactNode } from "react";

export interface FaqSimilarTopicsCardProps {
  similiarTopics?: {
    activeTopic: { value: string; name?: string };
    related: { value: string; name?: ReactNode }[];
  };
  onTopicSelect: (newSelectedTopic?: string) => void;
  isLoading: boolean;
}

const FaqSimilarTopicsCard = ({
  similiarTopics,
  isLoading,
  onTopicSelect,
}: FaqSimilarTopicsCardProps) => {
  const { t } = useTranslation();

  const topicItems = similiarTopics?.related.map((label) => {
    return {
      item_key: label.value.toLowerCase(),
      label: label.name || label.value,
    };
  });

  return (
    <Card>
      <CardHeader
        actions={[
          {
            icon: faGripHorizontal,
          },
        ]}
      >
        {t("Similar topics")}
      </CardHeader>
      <TableList
        itemsLoaded={!isLoading}
        loading={isLoading}
        pageSize={topicItems?.length}
        total={topicItems?.length}
        components={[]}
        onItemClick={(item) => {
          onTopicSelect(item.item_key);
        }}
        cols={[
          {
            key: "label",
            label: t("Label"),
            maxWidth: "100%",
          },
        ]}
        items={topicItems}
      />
    </Card>
  );
};

export default FaqSimilarTopicsCard;
