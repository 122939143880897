import { useEffect } from "react";
import { Card, CardHeader } from "@casasoft/styleguide/components/card";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faAlarmClock,
  faChevronDown,
} from "@fortawesome/pro-light-svg-icons";
import { TableList } from "@casasoft/styleguide/components/table-list";
import csMoment from "utilities/csMoment";
import resourceGrabber from "utilities/resourceGrabber";
import Button from "@casasoft/styleguide/components/forms/Button";
import { useQuickReminder } from "entities/event/quickReminderStore";
import { mainReminderResource } from "entities/event/mainReminderStore";

interface DashboardQuickremindersCardProps {
  onChange?: () => void;
  compactView?: boolean;
}

const DashboardQuickremindersCard = ({
  onChange,
  compactView,
}: DashboardQuickremindersCardProps) => {
  const { t } = useTranslation();
  const {
    store: quickReminderStore,
    fetchList: fetchQuickReminderList,
    updateItem: updateQuickReminderItem,
    reloadList: reloadQuickReminderList,
  } = useQuickReminder();
  const casaoneUser = resourceGrabber.grab("casaoneUser", "auth");

  useEffect(() => {
    const userContactId =
      casaoneUser?.contact?.id || "user-has-no-contact-defined";

    fetchQuickReminderList({
      filter: [
        {
          type: "innerjoin",
          field: "attendees",
          alias: "attendee_recipient",
          condition: "attendee_recipient.attendeeType = 'recipient'",
          conditionType: "with",
        },
        {
          type: "eq",
          field: "contact",
          alias: "attendee_recipient",
          value: userContactId,
        },
        {
          type: "in",
          field: "status",
          values: ["fresh", "seen"],
          where: "and",
        },
        {
          type: "between",
          field: "start",
          from: csMoment()
            .subtract(30, "day")
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:59"),
          to: csMoment()
            .add(30, "day")
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:59"),
          where: "and",
        },
      ],
      orderBy: [
        {
          type: "field",
          field: "start",
          direction: "asc",
        },
      ],
      pageSize: 10,
    });
  }, [casaoneUser, fetchQuickReminderList]);

  // trigger the change when fetch is finished or store gets updated
  useEffect(() => {
    onChange?.();
  }, [onChange, quickReminderStore]);

  const listItems =
    quickReminderStore?.items?.map((elem) => {
      const startRender = elem.start
        ? csMoment(elem.start.date).format("L LT")
        : "?";
      const isOld =
        elem.start && csMoment(elem.start.date).isBefore(csMoment())
          ? true
          : false;
      const startRenderS = elem.start.date;
      const senderName = elem._embedded?.sender?.displayName || "";
      const threadId = elem._embedded?.eventTrigger?._links?.self?.href
        .split("/")
        .pop();
      const state = <FontAwesomeIcon icon={faAlarmClock} />;

      const subjectRow = (
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {elem.subject || "-"}
        </div>
      );
      const startRenderRow = (
        <div
          className={isOld ? "tw-text-cs-danger" : ""}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {startRender}
        </div>
      );

      return {
        id: elem.id,
        item_key: elem.id,
        senderName,
        start_render: startRenderRow,
        start_render_s: startRenderS,
        state,
        subject: subjectRow,
        propertyId: elem._embedded?.property?.id || null,
        threadId,
        compact_content: (
          <div>
            {subjectRow}
            {startRenderRow}
          </div>
        ),
      };
    }) || [];

  const loadMore = () => {
    const userContactId =
      casaoneUser?.contact?.id || "user-has-no-contact-defined";
    if (quickReminderStore.listMetadata?.pageSize) {
      fetchQuickReminderList({
        filter: [
          {
            type: "eq",
            field: "recipient",
            value: userContactId,
          },
          {
            type: "in",
            field: "status",
            values: ["fresh", "seen"],
            where: "and",
          },
          {
            type: "between",
            field: "start",
            from: csMoment()
              .subtract(30, "day")
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:59"),
            to: csMoment()
              .add(30, "day")
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:59"),
            where: "and",
          },
        ],
        orderBy: [
          {
            type: "field",
            field: "start",
            direction: "asc",
          },
        ],
        pageSize: quickReminderStore.listMetadata.pageSize + 10,
      });
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          actions={[
            {
              icon: faAlarmClock,
            },
          ]}
        >
          {t("Reminders")}
        </CardHeader>
        <TableList
          loading={quickReminderStore.isFetching}
          itemsLoaded={
            quickReminderStore &&
            "listMetadata" in quickReminderStore &&
            typeof quickReminderStore.listMetadata?.totalItems === "number"
              ? true
              : false
          }
          total={listItems.length}
          cols={
            !compactView
              ? [
                  {
                    key: "state",
                    label: "Status",
                    maxWidth: 40,
                    placeholder: "circle",
                  },
                  {
                    key: "subject",
                    label: "Reminder",
                  },
                  {
                    key: "start_render",
                    label: "Time",
                    maxWidth: 150,
                  },
                ]
              : [
                  {
                    key: "state",
                    label: "Status",
                    maxWidth: 40,
                    placeholder: "circle",
                  },
                  {
                    key: "compact_content",
                    label: t("Info"),
                  },
                ]
          }
          items={listItems}
          components={["item-actions"]}
          itemPath={(item) => {
            return `/event/list/item/${item.threadId || item.id}`;
          }}
          itemActionNodes={(item) => [
            {
              label: t("Complete"),
              icon: faCheck,
              onItemClick: async () => {
                await updateQuickReminderItem(
                  item.id,
                  {
                    status: "done",
                  },
                  true
                );
                reloadQuickReminderList();
                mainReminderResource.reloadList();
              },
            },
          ]}
        />
        {listItems &&
          quickReminderStore.listMetadata &&
          quickReminderStore.items.length <
            quickReminderStore.listMetadata.totalItems && (
            <Button
              isBlock
              onClick={() => {
                loadMore();
              }}
            >
              <FontAwesomeIcon icon={faChevronDown} fixedWidth />
            </Button>
          )}
      </Card>
      <div className="tw-mb-4" />
    </>
  );
};

export default DashboardQuickremindersCard;
