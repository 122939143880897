import { EventShape } from "entities/event/types";
import csMoment from "utilities/csMoment";

const isPublicEvent = (event: EventShape): boolean => !event.private;

const getAuthor = (event: EventShape): string =>
  event.transcript?.smtp?.fromName ||
  event._embedded?.sender?.displayName ||
  "?";

const getEmail = (event: EventShape): string =>
  event.transcript?.smtp?.fromEmail || event._embedded?.sender?.email || "?";

const getTime = (event: EventShape): string =>
  event.start ? csMoment(event.start.date).format("MMMM Do YYYY, HH:mm") : "?";

const createEventHeader = (event: EventShape): string => {
  const name = getAuthor(event);
  const email = getEmail(event);
  const time = getTime(event);

  return `<blockquote type="cite">
    <hr />
    <p>
      ${event.type === "note" ? "Notiz: " : ""}
      ${time}, ${name} <${email}>:
    </p>
    <hr />
    ${event.html}
  </blockquote>`;
};

export const EMPTY_BODY_HTML = "<p><br /></p>";

export const getMessageHistoryHtml = (
  messageEvents: EventShape[] = []
): string | undefined => {
  return messageEvents.length
    ? [...messageEvents.filter(isPublicEvent).map(createEventHeader)].join("")
    : undefined;
};
