import i18n from "./i18n";
import resourceGrabber from "./resourceGrabber";

const getIs24termLink = () => {
  switch (resourceGrabber.grab("lang", "auth")) {
    case "de":
      return "https://www.immoscout24.ch/de/info/5567";
    case "fr":
      return "https://www.immoscout24.ch/fr/info/5568";
    case "it":
      return "https://www.immoscout24.ch/it/info/5569";
    default:
      return "https://www.immoscout24.ch/en/info/5570";
  }
};

const getHomegateTermLink = () => {
  switch (resourceGrabber.grab("lang", "auth")) {
    case "de":
      return "https://www.homegate.ch/c/de/ueber-uns/rechtliches/leistungsbeschrieb";
    case "fr":
      return "https://www.homegate.ch/c/fr/tout-sur-nous/informations-juridiques/description-des-prestations";
    case "it":
      return "https://www.homegate.ch/c/it/chi-siamo/informazioni-giuridiche/descrizione-delle-prestazioni";
    default:
      return "https://www.homegate.ch/c/en/about-us/legal-issues/description-of-services";
  }
};

export const getSmgRealEstateLink = () => {
  switch (resourceGrabber.grab("lang", "auth")) {
    case "de":
      return "https://swissmarketplace.group/de/real-estate/";
    case "fr":
      return "https://swissmarketplace.group/fr/immobilier/";
    case "it":
      return "https://swissmarketplace.group/de/real-estate/"; // There is no Italian version of the SMG website
    default:
      return "https://swissmarketplace.group/real-estate/";
  }
};

export const getMaisonWorkLink = () => {
  switch (resourceGrabber.grab("lang", "auth")) {
    case "de":
      return "https://www.maison.work/gtc/";
    case "fr":
      return "https://www.maison.work/fr/gtc/";
    case "it":
      return "https://www.maison.work/en/gtc/"; // There is no Italian version of the maison.work website
    default:
      return "https://www.maison.work/en/gtc/";
  }
};

// hardcoded, because these translations are a bit too specific sometimes for toplisting. just leave it if it works, okay? :)
const translatedQuickTerms: {
  [key: string]: {
    day: string;
    days: string;
    month: string;
  };
} = {
  en: {
    day: "day",
    days: "days",
    month: "month",
  },
  fr: {
    day: "jour",
    days: "jours",
    month: "mois",
  },
  de: {
    day: "Tag",
    days: "Tage",
    month: "Monat",
  },
  it: {
    day: "giorno",
    days: "giorni",
    month: "Mese",
  },
  es: {
    day: "día",
    days: "días",
    month: "mes",
  },
};

function getTranslatedQuickTerm(term: "day" | "days" | "month") {
  return translatedQuickTerms[i18n.language]?.[term] || term;
}

export function getPublishers() {
  const publicationInfoMaisonWork = (
    <>
      <a
        href="https://www.maison.work/"
        target="_blank"
        style={{ color: "white", textDecoration: "underline" }}
        rel="noreferrer"
      >
        maison.work
      </a>{" "}
      {i18n.t(
        "only publishes advertisements from the commercial sector. Residential space is automatically filtered out. If you have any questions, please contact the"
      )}{" "}
      <a
        href={getMaisonWorkLink()}
        target="_blank"
        style={{ color: "white", textDecoration: "underline" }}
        rel="noreferrer"
      >
        {i18n.t("Portal")}
      </a>
      {i18n.t("directly.")}
    </>
  );

  const publisherList = {
    immostreet: {
      name: "immostreet.ch",
      logo: "",
      disclaimer: "",
      range: true,
      max_images: null,
      max_documents: null,
      promotions: [],
      export_extra_statuses: [],
    },
    immowelt: {
      name: "immowelt.ch",
      logo: "",
      disclaimer: "",
      range: true,
      max_images: 13,
      max_documents: 1,
      promotions: [],
      export_extra_statuses: [],
    },
    immobilier: {
      name: "immobilier.ch",
      logo: "",
      disclaimer: "",
      range: true,
      max_images: 13,
      max_documents: 1,
      promotions: [],
      export_extra_statuses: [],
    },
    icasa: {
      name: "icasa.ch",
      logo: "https://icasa.ch/img/logo.svg",
      disclaimer: "",
      range: true,
      max_images: null,
      max_documents: null,
      promotions: [
        {
          channel: "top-promotion",
          label: `Top Promotion CHF 5.– / ${getTranslatedQuickTerm("day")}`,
          description: "Gets shifted to Top in Result-Lists",
        },
        {
          channel: "premium-selection",
          label: `Premium Selection CHF 15.– / ${getTranslatedQuickTerm(
            "day"
          )}`,
          description: "Home-Page",
        },
      ],
      export_extra_statuses: [],
    },
    homegate: {
      name: "homegate.ch",
      logo: "https://www.homegate.ch/ver-20180627152808/assets/img/homegate-logo-small.png",
      disclaimer: `<p>${i18n.t(
        "Terms of use of the top/premium listing at"
      )} <a href="${getHomegateTermLink()}" target="_blank">homegate.ch</a>.</p>`,
      range: true,
      max_images: 13,
      max_documents: 1,
      promotions: [
        {
          channel: "HG-TL",
          label: "TopListing",
          description: "",
        },
        {
          channel: "HG-PL",
          label: "PremiumListing",
          description: "",
        },
      ],
      export_extra_statuses: [],
    },
    smg: {
      name: "SMG (Swiss Marketplace Group)",
      disclaimer: `<p>${i18n.t(
        "Terms of use of the Top/PremiumListing by"
      )} <a href="${getHomegateTermLink()}" target="_blank">SMG (Swiss Marketplace Group)</a>.</p>`, // it's correct that this link points to homegate
      range: true,
      max_images: null,
      max_documents: null,
      promotions: [
        {
          channel: "SMG-TL",
          label: "TopListing",
          description: "",
        },
        {
          channel: "SMG-PTL",
          label: "PremiumListing",
          description: "",
        },
      ],
      single_publication_info: (
        <>
          {i18n.t(
            "SMG (Swiss Marketplace Group) portals include homegate.ch, ImmoScout24, Acheter-Louer.ch and many"
          )}{" "}
          <a
            href={getSmgRealEstateLink()}
            target="_blank"
            style={{ color: "white", textDecoration: "underline" }}
            rel="noreferrer"
          >
            {i18n.t("more")}
          </a>
          {"."}
        </>
      ),
      export_extra_statuses: [],
    },
    "acheter-louer": {
      name: "acheter-louer.ch",
      logo: "",
      disclaimer: "",
      range: true,
      max_images: 13,
      max_documents: 1,
      promotions: [],
      export_extra_statuses: [],
    },
    immoscout24: {
      name: "immoscout24.ch",
      logo: "https://www.immoscout24.ch/Themes/IS24/FEVersions/Default/img/logo/immoscout24.svg",
      disclaimer: `<p>${i18n.t("Top listing can be deactivated at any time.")}
          <br>${i18n.t(
            "Prices & conditions according to your contract with ImmoScout24."
          )}
        </p>
        `,
      range: true,
      max_images: 13,
      max_documents: 1,
      promotions: [
        {
          channel: "IS24-TL",
          label: "Top Listing",
          description: "",
        },
      ],
      single_publication_info: (
        <>
          <p>
            {i18n.t(
              "ImmoScout24 (IS24) automatically forwards listings for new construction and renovation properties internally to the IS24 new construction platform."
            )}
          </p>
          {i18n.t("IS24 claims the following terms")}:{" "}
          <a
            href={getIs24termLink()}
            target="_blank"
            style={{ color: "white", textDecoration: "underline" }}
            rel="noreferrer"
          >
            {i18n.t("Member provisions")}
          </a>
        </>
      ),
      export_extra_statuses: [],
    },
    urbanhome: {
      name: "urbanhome.ch",
      logo: "",
      disclaimer: `<p>Inserat ist 30 Tage in der Prime Kategorie
        </p>
        <p class="tw-text-cs-danger">Toplisting CHF 50.– / ${getTranslatedQuickTerm(
          "month"
        )}</p>
        `,
      range: false,
      max_images: null,
      max_documents: null,
      promotions: [
        {
          channel: "UH-TL30",
          label: "Top Listing",
          description: "",
          duration: 30,
        },
      ],
      export_extra_statuses: [],
    },
    immoscout24_2: {
      name: "immoscout24.ch 2",
      logo: "https://www.immoscout24.ch/Themes/IS24/FEVersions/Default/img/logo/immoscout24.svg",
      disclaimer: `<p>${i18n.t("Top listing can be deactivated at any time.")}
          <br>${i18n.t(
            "Prices & conditions according to your contract with ImmoScout24."
          )}
        </p>
        `,
      range: true,
      max_images: 13,
      max_documents: 1,
      promotions: [
        {
          channel: "IS24-TL",
          label: "Top Listing",
          description: "",
        },
      ],
      export_extra_statuses: [],
    },
    newhome: {
      name: "newhome.ch",
      logo: "https://www.newhome.ch/ressourcen/NEW/newhome.png",
      disclaimer: `<p>${i18n.t(
        "Promotions are billed directly via {{website}}. You will receive the invoice directly to the e-mail address stored in the system.",
        {
          website:
            '<a href="http://newhome.ch" target="_blank">newhome.ch AG</a>',
        }
      )}</p>`,
      range: false,
      max_images: null,
      max_documents: null,
      promotions: [
        {
          channel: "NH-TP15",
          label: `Top Promotion 15 ${getTranslatedQuickTerm("days")} CHF 99.–`,
          description: "",
          duration: 15,
        },
        {
          channel: "NH-TP30",
          label: `Top Promotion 30 ${getTranslatedQuickTerm("days")} CHF 169.–`,
          description: "",
          duration: 30,
        },
        {
          channel: "NH-TP60",
          label: `Top Promotion 60 ${getTranslatedQuickTerm("days")} CHF 219.–`,
          description: "",
          duration: 60,
        },
        // {
        //   channel: 'NH-TP10',
        //   label:`'Special Promotion 10 ${getTranslatedQuickTerm(
        //
        //   "days"
        // )} CHF 149.–`,
        //   description: '',
        // },
        {
          channel: "NH-SP15",
          label: `Special Promotion 15 ${getTranslatedQuickTerm(
            "days"
          )} CHF 149.–`,
          description: "",
          duration: 15,
        },
        {
          channel: "NH-SP30",
          label: `Special Promotion 30 ${getTranslatedQuickTerm(
            "days"
          )} CHF 219.–`,
          description: "",
          duration: 30,
        },
        {
          channel: "NH-SP60",
          label: `Special Promotion 60 ${getTranslatedQuickTerm(
            "days"
          )} CHF 269.–`,
          description: "",
          duration: 60,
        },
        {
          channel: "NH-HL15",
          label: `Highlight Promotion 15 ${getTranslatedQuickTerm(
            "days"
          )} CHF 119.–`,
          description: "",
          duration: 15,
        },
        {
          channel: "NH-HL30",
          label: `Highlight Promotion 30 ${getTranslatedQuickTerm(
            "days"
          )} CHF 189.–`,
          description: "",
          duration: 30,
        },
        {
          channel: "NH-HL60",
          label: `Highlight Promotion 60 ${getTranslatedQuickTerm(
            "days"
          )} CHF 239.–`,
          description: "",
          duration: 60,
        },
      ],
      export_extra_statuses: [],
    },
    neubauprojekte: {
      name: "neubauprojekte.ch",
      // logo: "https://www.TODO.ch/ressourcen/NEW/TODO.png",
      disclaimer: `<p>${i18n.t(
        "Promotions are billed directly via {{website}}. You will receive the invoice directly to the e-mail address stored in the system.",
        {
          website:
            '<a href="https://www.neubauprojekte.ch/" target="blank">Neubau Portal AG</a>',
        }
      )}</p>`,
      range: false,
      max_images: null,
      max_documents: null,
      promotions: [
        {
          channel: "NBP-TL7",
          label: `Top Listing 7 ${getTranslatedQuickTerm("days")} CHF 49.–`,
          description: "",
          duration: 7,
        },
        {
          channel: "NBP-TL14",
          label: `Top Listing 14 ${getTranslatedQuickTerm("days")} CHF 89.–`,
          description: "",
          duration: 14,
        },
        {
          channel: "NBP-TL30",
          label: `Top Listing 30 ${getTranslatedQuickTerm("days")} CHF 169.–`,
          description: "",
          duration: 30,
        },
      ],
      single_publication_info: i18n.t(
        "Neubauprojekte.ch might apply changes to the title on their Portal, once published, according to its guidelines (title must not contain an address)."
      ),
      export_extra_statuses: [],
    },
    "maison-work": {
      name: "maison.work",
      disclaimer: "",
      range: true,
      max_images: null,
      max_documents: null,
      promotions: [],
      single_publication_info: publicationInfoMaisonWork,
      export_extra_statuses: [],
    },
    "maisonwork-test": {
      name: "maison.work",
      disclaimer: "",
      range: true,
      max_images: null,
      max_documents: null,
      promotions: [],
      single_publication_info: publicationInfoMaisonWork,
      export_extra_statuses: [],
    },
  };
  // future newhome promotions
  /*
    Special Promotion 15 Tage CHF 149 à NH-SP15
    Special Promotion 30 Tage CHF 219 à NH-SP30
    Special Promotion 60 Tage CHF 269 à NH-SP60
  */

  return publisherList;
}

type PublisherItems = ReturnType<typeof getPublishers>;
type PublisherKeys = keyof PublisherItems;

export function isPublisher(key?: string): key is PublisherKeys {
  return !!key && !!getPublishers()[key as PublisherKeys];
}

export function getPublisherItem<TKey extends string>(
  key?: TKey
): TKey extends PublisherKeys
  ? PublisherItems[TKey]
  : PublisherItems[PublisherKeys] | null {
  // TODO: fix any types
  if (key && isPublisher(key)) {
    const publishers = getPublishers();
    const item = publishers[key as PublisherKeys];
    return item as any;
  }
  return null as any;
}
