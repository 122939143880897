import createBoardingInstance from "@casasoft/styleguide/utilities/tours/createBoardingInstance";
import completeAndBackToOverview from "tours/completeAndBackToOverview";
import { waitForElm } from "tours/utilities";
import { USER_TOUR_USER_APP_CONTENT } from "./userCreateOnboarding";

export const USER_DELETE_TOUR = "usr-rm-tour";

export function subscribeUserDeleteOnboarding(t: (term: string) => string) {
  const [boarding, unsubscribe] = createBoardingInstance(USER_DELETE_TOUR, t, {
    allowClose: false,
    padding: 5,
    strictClickHandling: "block-all", // we are doing the clicking for them
  });

  boarding.defineSteps([
    {
      element: `[data-boarding~="${USER_TOUR_USER_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto > a`,
      popover: {
        title: t("usersDelete.intro.title"),
        description: t("usersDelete.intro.desc"),
        prefferedSide: "top",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${USER_TOUR_USER_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto > a`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${USER_TOUR_USER_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto > a .cs-tablelist-itemaction-btn-tw-helper`,
      popover: {
        title: t("usersDelete.quickActions.title"),
        description: t("usersDelete.quickActions.desc"),
        prefferedSide: "left",
      },
    },
    {
      element: `[data-radix-portal] div[role="menu"][data-state="open"] div[role="menuitem"] button`,
      popover: {
        title: t("usersDelete.delete.title"),
        description: t("usersDelete.delete.desc"),
        prefferedSide: "left",
        prevBtnText: t("onboarding.btn.backToOverview"),
      },
      onPrevious: () => {
        completeAndBackToOverview(boarding);
      },
      prepareElement: async () => {
        boarding.preventMove();
        const dropdownToggl = document.querySelector<HTMLDivElement>(
          `[data-boarding~="${USER_TOUR_USER_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto > a .cs-tablelist-itemaction-btn-tw-helper button`
        );

        let dropdownItem = document.querySelector(
          `[data-radix-portal] div[role="menu"][data-state="open"] div[role="menuitem"] button`
        );
        if (!dropdownItem) {
          // dropdownToggl?.click();
          dropdownToggl?.dispatchEvent(
            new KeyboardEvent("keydown", { bubbles: true, key: "Enter" })
          );
          // dropdownToggl?.dispatchEvent(
          //   new MouseEvent("click", { button: 0, bubbles: true })
          // );
          dropdownItem = await waitForElm(
            `[data-radix-portal] div[role="menu"][data-state="open"] div[role="menuitem"] button`
          );
        }

        boarding.continue();
      },
    },
  ]);

  return unsubscribe;
}
