import { GetMessagePreviewSingleBody } from "api/entities/messagePreview/endpoints/single/types";
import { UseFormGetValues } from "react-hook-form";
import { MessageCreateFormShape } from "../../MessageCreateForm";
import { NEW_EMAIL_PREFIX } from "components/message/features/recipients-select/RecipientSelect/RecipientSelectNewEmailOption";
import { RecipientSelectValueType } from "components/message/features/recipients-select/RecipientSelect/helpers/useRecipientSelect";
import { insertAttachmentLinksTag } from "../signatureManager";

export type ContactForPreview = {
  email: string;
  id?: string;
  contactIdentifier: string;
  lang?: string;
};

const mapRecipientToContactForPreview = (
  contact: RecipientSelectValueType
): ContactForPreview => ({
  id: contact.value,
  email: contact.contact?.email || "",
  contactIdentifier: contact.contact?.id || "",
  lang: contact.contact?.lang || "",
});

export const getAllContacts = (
  getValues: UseFormGetValues<MessageCreateFormShape>
): ContactForPreview[] => {
  const allContacts =
    getValues("contact")
      ?.concat(getValues("contactCC") || [])
      .concat(getValues("contactBCC") || []) || [];

  return allContacts.map(mapRecipientToContactForPreview);
};

export const hasMoustacheVariables = (text: string = ""): boolean => {
  const RE_MUSTACHE_VARIABLES = /\{\{.*\}\}/g;
  return !!text?.match(RE_MUSTACHE_VARIABLES);
};

export const getPreviewRequestBody = (
  getValues: UseFormGetValues<MessageCreateFormShape>,
  sender: ContactForPreview,
  recipient: ContactForPreview | undefined
): GetMessagePreviewSingleBody | undefined => {
  if (!recipient || !hasMoustacheVariables(getValues("body"))) {
    return undefined;
  }

  return {
    recipient:
      !recipient.id || recipient.id?.includes(NEW_EMAIL_PREFIX)
        ? { email: recipient.email }
        : recipient.id,
    sender: sender.contactIdentifier,
    property: getValues("property")?.value,
    html: insertAttachmentLinksTag(getValues("body")) || "",
    lang: recipient.lang || getValues("lang") || sender.lang || "de", // language fallback: recipient lang -> form lang -> sender lang -> de
  };
};
