import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetPropertyQuery } from "api/entities/property";
import { useMemo } from "react";
import {
  filterContentMediaDocuments,
  mapMediaToCustomShape,
} from "./propertyDocumentsHelpers";
import { PropertyAttachment } from "./propertyAttachment.model";

interface UsePropertyDocumentsProps {
  propertyId: string | undefined;
}

export const usePropertyDocuments = ({
  propertyId,
}: UsePropertyDocumentsProps) => {
  const { data: propertyData, isLoading } = useGetPropertyQuery(
    propertyId ?? skipToken
  );

  const documents = useMemo(() => {
    let result: PropertyAttachment[] = [];

    propertyData?._embedded.contents?.forEach((contentByLang) => {
      const contentMedias = contentByLang._embedded?.contentMedias;

      if (contentMedias) {
        result = [
          ...result,
          ...filterContentMediaDocuments(contentMedias).map(
            mapMediaToCustomShape
          ),
        ];
      }
    });

    return result;
  }, [propertyData]);

  return { documents, isLoading };
};
