import { useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { TableListBulkSelectHeader } from "./TableListBulkSelectHeader";
import { TableListBulkSelectControl } from "../../hooks/useTableListBulkSelect";
import { DropdownProps } from "../dropdown";
import TableListItem from "./TableListItem";
import TableListHeader from "./TableListHeader";
import TableListFooter from "./TableListFooter";
import TableListItemsWrapper from "./TableListItemsWrapper";
import TableListActions, {
  SearchField,
  TableListActionsProps,
} from "./TableListActions";
import TableListCardBody from "./TableListCardBody";
import { TableListItemCol } from "./TableListItemCols";

export interface Item {
  item_key: string;
  item_active?: boolean;

  [key: string]: any;
}

export interface TableListSortConf {
  key: string | null;
  dir: "asc" | "desc" | null;
}

/**
 * Table List
 */
export interface TableListProps
  extends Pick<
    TableListActionsProps,
    | "mainActionNode"
    | "mainActionNodes"
    | "searchFields"
    | "filterForm"
    | "filterFormSelect"
  > {
  /** Array of Items for the List (only provide the ones that should be displayed for the current page) */
  items?: Item[];
  /** Columns configuration. Flex, width, label, etc. */
  cols?: TableListItemCol[];
  /** Renders a NavLink component based to URL return from itemPath */
  itemPath?: (item: Item) => string | undefined;
  /** Alternative to "itemPath". Renders <a> tag based to URL return from itemLink */
  itemLink?: (item: Item) => string;
  /** Alternative to "itemPath" and "itemLink". Custom click handler for Item */
  onItemClick?: (item: Item) => void;
  /**
   * Renders each Item's quick actions.
   * Requires the "item-actions" component to be enabled.
   * */
  itemActionNodes?: (item: Item) => DropdownProps["items"] | undefined;
  /** Renders each Item's value, but ONLY for when cardView is set to TRUE */
  renderValue?: (item: any, key: string) => any[];
  /** Array of components that should get rendered */
  components?: (
    | "header"
    | "search"
    | "filter"
    | "actions"
    | "pagination"
    | "item-actions"
  )[];
  /** Current page size. Mainly used for pagination. */
  pageSize?: number;
  /** Current page index. Mainly used for pagination. */
  page?: number;
  /** Describes the TOTAL items loaded. Mainly used for pagination. */
  total?: number;
  itemsLoaded: boolean;
  /**
   * Prop used by TableListContainer to handle search input
   * Requires the "search" component to be enabled.
   * */
  defaultSearchValue?: string;
  /**
   * Prop used by TableListContainer to handle search input
   * Requires the "search" component to be enabled.
   * */
  searchFieldFilter?: string;
  /**
   * default sort key for different sort behavior
   * */
  defaultSortKey?: string;
  /**
   * default sort dir for different sort behavior
   * */
  defaultSortDir?: "asc" | "desc" | null;
  /**
   * Search input change handler. Mainly used by TableListContainer.
   * Requires the "search" component to be enabled.
   * */
  onSearchChange?: (search: string, searchField?: string) => void;
  /**
   * Page size change handler. Mainly used by TableListContainer.
   * Requires the "pagination" component to be enabled.
   * */
  onPageSizeChange?: (pageSize: number) => void;
  /**
   * Page index change handler. Mainly used by TableListContainer.
   * Requires the "pagination" component to be enabled.
   * */
  onPageChange?: (curPage: number) => void;
  /**
   * Sort change handler. Mainly used by TableListContainer.
   * Requires the "pagination" component to be enabled.
   * */
  onSortChange?: (sortConf: TableListSortConf) => void;
  /** Prop that controls wheather the list should signify a loading state */
  loading?: boolean;
  /** Alternate display mode. Cards instead of plane list. */
  cardView?: boolean;
  /** Whether a <SortableList /> wrapper should be rendered for the TableList to be drag-and-drop sortable. */
  sortable?: boolean;
  /** Handler for changing the lists sorting after enabling "sortable" prop. */
  onNewSortOrder?: (arg: { oldIndex: number; newIndex: number }) => void;
  /** Which pageSizes are available inside the Page Size dropdown */
  paginationOptions?: number[];

  /** Defaults to "true" (but mini mode will always disable it). It adds a wrapper around the Rendered component with a padding for usage inside cards. Most usecases of the TableList are inside cards */
  cardBody?: boolean;
  /** Mini view can be a string which will be the link back to the full view. In mini view certain elements are not rendered */
  mini?: false | string;
  /** Control object returned by the TableListBulkSelect hook. If this prop is provided, TableList will render BulkSelect */
  bulkSelectControl?: TableListBulkSelectControl;
  /**
   * Event that is fired when "toggleAll" checkbox is clicked (BEFORE toggleAll gets called).
   * If the returned value is "false", the toggleAll action will NOT fire.
   * Recieves the state whether some items are selected as an argument.
   * Requires bulkSelectControl to be set.
   * */
  onBulkToggleAll?: (hasHadSelectedItems: boolean) => false | void;
}

const TableList = ({
  items = [],
  cols = [],
  pageSize = 10,
  page = 1,
  total = 0,
  itemsLoaded = true,
  defaultSearchValue = "",
  searchFieldFilter = "",
  defaultSortKey = "",
  defaultSortDir = "asc",
  mainActionNode = null,
  mainActionNodes = [],
  onSearchChange,
  onPageSizeChange,
  onPageChange,
  onSortChange,
  onNewSortOrder,
  onItemClick,
  itemPath = (item) => {
    return "";
  },
  itemLink,
  itemActionNodes,
  renderValue = (item, key) => {
    return item[key];
  },
  components = [
    "header",
    "search",
    "filter",
    "actions",
    "pagination",
    "item-actions",
  ],
  loading = false,
  cardView = false,
  sortable = false,
  paginationOptions,
  searchFields = [],
  filterForm,
  filterFormSelect,
  cardBody = true,
  mini = false,
  bulkSelectControl,
  onBulkToggleAll,
}: TableListProps) => {
  const foundSearchFieldFilter = searchFields.find(
    (f) => f.key === searchFieldFilter
  );
  const initialSearchField = foundSearchFieldFilter || searchFields[0] || {};

  const [currentSearchField, setCurrentsearchField] =
    useState<SearchField>(initialSearchField);
  const { width, ref: _tablelistElement } = useResizeDetector<HTMLDivElement>();
  // trigger smallModeBelow if component is smaller then 670 in width

  const isSmallMode =
    !mini && typeof width !== "undefined" && width < 670 ? true : false;
  const [sortConf, setSortConf] = useState<TableListSortConf>({
    key: null,
    dir: null,
  });

  const totalItems = !itemsLoaded ? pageSize : total;
  const nrOfPages =
    Math.ceil(totalItems / pageSize) === 0
      ? 1
      : Math.ceil(totalItems / pageSize);
  const itemsNeeded =
    page === nrOfPages ? totalItems - pageSize * (nrOfPages - 1) : pageSize;

  // filler items
  const nrMissingItems = Math.max(itemsNeeded - items.length, 0); // prevent falling below 0 -> can happen when items.length is still given from a previous page with more items

  const showPagination = components.includes("pagination");
  const showHeader = components.includes("header") && !cardView && !mini;
  const showBulkHeader = !!(bulkSelectControl && !mini);

  const showActions = !!(
    filterForm &&
    (components.includes("actions") ||
      components.includes("search") ||
      components.includes("filter"))
  );
  if (
    !showActions &&
    !items.length &&
    !nrMissingItems &&
    !showPagination &&
    !showHeader &&
    !showBulkHeader
  ) {
    return <></>;
  }

  return (
    <TableListCardBody isEnabled={!mini && cardBody}>
      <div
        ref={_tablelistElement}
        className={`${mini ? "tw-p-3" : "tw--mb-4"} ${
          loading ? "tw-opacity-50 tw-pointer-events-none" : ""
        }`}
      >
        <TableListActions
          searchFields={searchFields}
          filterForm={filterForm}
          filterFormSelect={filterFormSelect}
          mainActionNode={!mini ? mainActionNode : undefined}
          mainActionNodes={!mini ? mainActionNodes : undefined}
          currentSearchField={currentSearchField}
          defaultSearchValue={defaultSearchValue}
          onSearchFieldChange={(newVal) => setCurrentsearchField(newVal)}
          onDelaySearchChange={(search, searchField) => {
            onSearchChange?.(search, searchField);
          }}
          showSearch={components.includes("search")}
          showFilter={components.includes("filter")}
          showActions={components.includes("actions")}
          isSmallMode={isSmallMode}
          mini={mini}
        />
        <TableListItemsWrapper
          sortable={sortable}
          cardView={cardView}
          mini={!!mini}
          itemKeys={items.map((item) => item.item_key)}
          onSortEnd={({ oldIndex, newIndex }) => {
            onNewSortOrder?.({ oldIndex, newIndex });
          }}
        >
          {showBulkHeader && (
            <TableListBulkSelectHeader
              isSingle={bulkSelectControl.isSingle}
              bulkSelected={bulkSelectControl.bulkSelectedFullItem}
              bulkActionNodes={bulkSelectControl.bulkActionNodes}
              onBulkToggle={() => {
                const onToggleAllReturn = onBulkToggleAll?.(
                  !!bulkSelectControl.bulkSelected.length
                );

                if (onToggleAllReturn !== false) {
                  bulkSelectControl.toggleAll(items);
                }
              }}
            />
          )}
          {showHeader && (
            <TableListHeader
              cardView={cardView}
              sortable={sortable}
              cols={cols}
              onColumnClick={(colKey) => {
                // sort order after every click:
                // 1. STEP_A (opposite of step B)
                // 2. STEP_B (opposite of step A)
                // 3. back to default
                // 4. start over

                const STEP_A: "asc" | "desc" = "asc";
                const STEP_B: "asc" | "desc" = "desc";

                const newSortConf: TableListSortConf = {
                  dir: sortConf.dir,
                  key: colKey,
                };

                if (colKey !== defaultSortKey) {
                  if (
                    colKey !== sortConf.key // if different column was clicked -> start over
                  ) {
                    newSortConf.dir = STEP_A;
                  } else {
                    if (
                      sortConf.dir === STEP_B // 3rd click -> back to default
                    ) {
                      newSortConf.key = null;
                      newSortConf.dir = null;
                    } else if (
                      sortConf.dir === STEP_A // second click -> continue with STEP_B
                    ) {
                      newSortConf.dir = STEP_B;
                    }
                  }
                } else {
                  const NON_DEFAULT_DIR =
                    defaultSortDir === STEP_A ? STEP_B : STEP_A;

                  if (NON_DEFAULT_DIR === newSortConf.dir) {
                    newSortConf.key = null;
                    newSortConf.dir = null;
                  } else {
                    newSortConf.key = defaultSortKey;
                    newSortConf.dir = NON_DEFAULT_DIR;
                  }
                }
                setSortConf(newSortConf);
                onSortChange?.(newSortConf);
              }}
              defaultSortKey={defaultSortKey}
              defaultSortDir={defaultSortDir}
              sortActiveKey={sortConf.key}
              sortDirection={sortConf.dir}
              bulkSelectControl={!mini ? bulkSelectControl : undefined}
              availableItems={items}
            />
          )}
          {items.map((item) => {
            const idx = items.findIndex((x) => x.item_key === item.item_key);
            return (
              <TableListItem
                key={item.item_key}
                cols={cols}
                onItemClick={onItemClick}
                itemPath={itemPath}
                itemLink={itemLink}
                itemActionNodes={itemActionNodes}
                renderValue={renderValue}
                cardView={cardView}
                sortable={sortable}
                bulkSelectControl={!mini ? bulkSelectControl : undefined}
                // ..
                // ..
                // ..
                // ..
                itemIndex={idx !== -1 ? idx : null}
                item={item}
                mini={!!mini}
              />
            );
          })}
          {Array.from(Array(nrMissingItems).keys()).map((index) => (
            <TableListItem
              key={`filler-item-${index}`}
              cols={cols}
              onItemClick={onItemClick}
              itemPath={itemPath}
              itemLink={itemLink}
              itemActionNodes={itemActionNodes}
              renderValue={renderValue}
              cardView={cardView}
              sortable={sortable}
              bulkSelectControl={!mini ? bulkSelectControl : undefined}
              // ..
              // ..
              // ..
              // ..
              itemIndex={null}
              item={{
                item_key: `filler-item-${index}`,
                isFiller: true,
              }}
              mini={!!mini}
            />
          ))}
        </TableListItemsWrapper>
        {showPagination && (
          <TableListFooter
            nrOfPages={nrOfPages}
            totalItems={totalItems}
            curPage={page}
            pageSize={pageSize}
            paginationOptions={paginationOptions}
            onPageChange={(newPage) => {
              if (onPageChange) {
                onPageChange(newPage);
              }
            }}
            onPageSizeChange={(newPageSize) => {
              if (onPageSizeChange) {
                // check if the page exists
                if (onPageChange && page > 1 && newPageSize * page > total) {
                  onPageChange(1);
                }
                onPageSizeChange(newPageSize);
              }
            }}
            mini={!!mini}
            isSmallMode={isSmallMode}
          />
        )}
      </div>
    </TableListCardBody>
  );
};

export default TableList;
