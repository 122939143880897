import { Card, CardHeader } from "@casasoft/styleguide/components/card";
import { useTranslation } from "react-i18next";
import {
  faQuestionCircle,
  faExternalLink,
} from "@fortawesome/pro-light-svg-icons";
import { TableList } from "@casasoft/styleguide/components/table-list";
import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "redux/hooks";
import { selectContactLang } from "redux/auth/selectors";

export interface HelpcenterArticlesCardProps {
  articles: { item_key: string; title: ReactNode; content: ReactNode }[];
  activeTopic?: string;
  isLoading: boolean;
  onArticleSelect: (newSelectedArticle: string | undefined) => void;
}

const HelpcenterArticlesCard = ({
  articles,
  isLoading,
  onArticleSelect,
  activeTopic,
}: HelpcenterArticlesCardProps) => {
  const { t } = useTranslation();
  const userLanguage = useAppSelector(selectContactLang);
  const fetchLang = userLanguage === "fr" ? "fr" : "de";

  return (
    <Card>
      <CardHeader
        actions={[
          {
            icon: faQuestionCircle,
          },
        ]}
      >
        <div className="tw-pr-4">
          {`${t("Recent questions about")} ${activeTopic}`}
        </div>
      </CardHeader>
      <TableList
        itemsLoaded={!isLoading}
        loading={isLoading}
        pageSize={6}
        total={articles.length}
        onItemClick={(item) => {
          onArticleSelect(item.item_key);
        }}
        components={[]}
        cols={[
          {
            key: "title",
            label: t("Title"),
            maxWidth: "100%",
          },
        ]}
        items={articles}
      />

      <a
        href={`https://help.casasoft.ch/hc/${fetchLang}/signin?return_to=https%3A%2F%2Fhelp.casasoft.ch%2Fhc%2F${fetchLang}`}
        target={"_blank"}
        className="tw-text-tw-cs-accent-color tw-p-4 tw-flex tw-items-center tw-justify-between tw-border-t-2 tw-border-solid tw-border-0 tw-border-cs-shade-50"
        rel="noreferrer"
      >
        <div className="tw-whitespace-nowrap tw-text-ellipsis">
          {t("View all articles in Helpcenter")}
        </div>
        <FontAwesomeIcon icon={faExternalLink} />
      </a>
    </Card>
  );
};

export default HelpcenterArticlesCard;
