/* eslint-disable deprecation/deprecation */
import { useAppSelector } from "redux/hooks";
import { selectContactLang, selectCasaoneUser } from "redux/auth/selectors";
import { i18n } from "utilities";
import { useTranslation } from "react-i18next";
import { mapPropertyAttachments } from "./propertyAttachmentsManager";
import resourceGrabber from "utilities/resourceGrabber";
import mailProviderUtility from "utilities/mailProviderUtility";
import { getContactAccessUrl } from "./contactAccessLinkUrl";
import { useState } from "react";
import { getCleanedUpMedia } from "./miscHelpers";
import {
  type EmailContact,
  contactListShouldReload,
  getAttendees,
  getContactId,
  getNewContactEmail,
} from "./attendeesManager";
import { EventShape } from "entities/event/types";
import { mainMessageResource } from "entities/event/mainMessageStore";
import csMoment from "utilities/csMoment";
import { mainReminderResource } from "entities/event/mainReminderStore";
import config from "config";
import { axiosInstance } from "utilities/axios";
import * as Sentry from "@sentry/browser";
import { quickReminderResource } from "entities/event/quickReminderStore";
import resourceHelper from "utilities/resource";
import { mainContactResource } from "entities/contact/mainStore";
import { type MessageCreateFormShape } from "../../MessageCreateForm";
import { useMessageAnalytics } from "../useMessagingAnalytics";
import { type SentMessageMetaData } from "../../SendingMessage/SendingMessageContainer";
import { insertAttachmentLinksTag } from "../signatureManager";
import { mergeMessageBodyWithHistory } from "./messageBodyManager";

export type OnDoneArg = EventShape | SentMessageMetaData[];

interface UseMessageFormOnSubmitProps {
  thread?: EventShape;
  onDone?: (messageEvent: OnDoneArg) => void;
  onFail?: (errors?: any[]) => void;
}

/** TODO: Refactor and cover with unit tests */
export const useMessageFormOnSubmit = ({
  thread,
  onDone,
  onFail,
}: UseMessageFormOnSubmitProps) => {
  const { t } = useTranslation();
  const userLanguage = useAppSelector(selectContactLang);
  const senderUserId = useAppSelector(selectCasaoneUser)?.contact?.id;
  const [messageCount, setMessageCount] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [sendCanceled, setSendCanceled] = useState(false);
  const [sentMessages, setSentMessage] = useState<SentMessageMetaData[]>([]);
  const {
    sendMessageSentEvent,
    sendMessageErrorEvent,
    prepareMessageSentEventParams,
  } = useMessageAnalytics();

  const onSubmit = async (data: MessageCreateFormShape) => {
    setIsSending(true);

    const contacts: string[] = data.contact?.map((c) => c.value) || [];

    const recipientsLang: string = data.lang || userLanguage || "de";
    await new Promise<void>((res) => {
      i18n.loadLanguages([recipientsLang], () => {
        res();
      });
    });

    if (contacts) {
      setMessageCount(contacts.length);

      const attachments = getCleanedUpMedia(data.attachments);
      const propertyAttachments = await mapPropertyAttachments({
        propertyAttachments: data.propertyAttachments,
        property: data.property?.property,
        t,
      });
      const mergedAttachments = [...propertyAttachments, ...attachments];

      const host = resourceGrabber.grab("casaoneUser", "auth")?.smtpHost;
      // foreach runs imidiately. We will add each item to a timeout queue to fire them throtteled in succession
      const delay = host ? mailProviderUtility.getThrottle(host) : 2000;

      const attendees = getAttendees(data.contactCC, data.contactBCC);

      let messagesFailedInARow = 0;

      let lastMessageDate = new Date(new Date().getTime() - delay); // initialize with delay subtracted, so the first message is not being throttled
      for (const contactString of contacts) {
        const contactId: string | EmailContact = getContactId(contactString);
        const contactEmail = getNewContactEmail(contactString);
        const contactFull = data.contact?.find(
          (c) => c.value === contactId
        )?.contact;
        const contactLang = contactFull?.lang || recipientsLang;

        const urls = getContactAccessUrl({
          sendContactLink: data.sendContactLink,
          language: contactLang,
          label: t("Edit my profile", { lng: contactLang }),
        });

        //     // stop sending when modal is closed
        //     if (!mounted.current) {
        //       break;
        //     }
        if (messagesFailedInARow >= 10) {
          setSendCanceled(true);
          setSentMessage((prevMessages) => [
            ...prevMessages,
            {
              contactSimple: contactEmail,
              contactFull,
              error: t("Too many messages failed to send"),
            },
          ]);
          onFail &&
            onFail([
              {
                type: "error",
                text: t("Too many messages failed to send"),
              },
            ]);
          break;
        }

        // throttle based on smptpHost recommended delay
        const newDate = new Date();
        const diffToOldDate = newDate.getTime() - lastMessageDate.getTime();
        const delayNeeded = delay - diffToOldDate;
        const delayNormalized = delayNeeded > 0 ? delayNeeded : 1; // make sure the dealy is not zero or lower

        await new Promise<void>((res) => {
          setTimeout(() => {
            res();
          }, delayNormalized);
        });

        // analytics
        const analyticsEventParams = prepareMessageSentEventParams(
          data,
          contactId,
          mergedAttachments
        );

        try {
          // create message event
          // TODO: Migrate to RTK query
          const messageEvent = await mainMessageResource.createItem(
            {
              recipient: contactId,
              sender: senderUserId,
              start: csMoment().format("YYYY-MM-DD HH:mm"),
              html: mergeMessageBodyWithHistory({
                body: insertAttachmentLinksTag(data.body),
                messageHistory: data.messageHistory,
                addMessageHistoryFlag: data.addMessageHistory,
              }),
              type: data.eventBaseType,
              eventType: data.eventType,
              property: data.property?.value,
              status: "fresh",
              subject: data.subject,
              attachments: mergedAttachments,
              urls,
              eventTrigger: thread?.id,
              lang: contactLang,
              attendees: attendees.length ? attendees : undefined,
              eventPhase: data.phase // if removed or changed
                ? {
                    phase: data.phase,
                  }
                : data.phase,
              requestedRelationType: data.relation,
            },
            true
          );
          if (!messageEvent) {
            throw new Error("Create message failed");
          }

          // create reminder (the reminder components are hidden when multiple recipients are involved (recipientList) is used)
          if (data.addReminderForm && messageEvent.id) {
            await mainReminderResource.createItem(
              {
                type: "reminder",
                sender: senderUserId,
                recipient: data.addReminderForm.user,
                start: data.addReminderForm.date
                  ? `${data.addReminderForm.date} ${
                      data.addReminderForm.hour || "00:00"
                    }`
                  : data.addReminderForm.date,
                subject: data.addReminderForm.subject,
                html: data.addReminderForm.notes,
                eventTrigger: messageEvent.id,
              },
              true
            );
          }

          lastMessageDate = new Date(); // update lastMessageSent date to calculate the throttle for the next message
          // send actual message
          const response = await axiosInstance.post(
            `${config.apiUrl}/${config.customerKey}/send-message/${messageEvent.id}`
          );

          if (response.status === 500) {
            const errorMessage = response.data.detail || undefined;

            // analytics
            sendMessageErrorEvent({
              ...analyticsEventParams,
              errorMessage,
            });

            throw new Error(
              errorMessage || "Sending message failed with status 500"
            );
          } else {
            // analytics
            sendMessageSentEvent(analyticsEventParams);

            // only call onDone methods for single-sent messages
            if (contacts.length === 1) {
              onDone && onDone(messageEvent);
            }
          }
          setSentMessage((prevMessages) => [
            ...prevMessages,
            {
              contactSimple: contactEmail,
              contactFull,
            },
          ]);

          // reset failed message counter
          messagesFailedInARow = 0;
        } catch (err: any) {
          // increase message failed in a row
          messagesFailedInARow++;

          // analytics
          sendMessageErrorEvent({
            ...analyticsEventParams,
            errorMessage: err?.message || "Sending message failed",
          });

          setSentMessage((prevMessages) => [
            ...prevMessages,
            {
              contactSimple: contactEmail,
              contactFull,
              error:
                typeof err === "object"
                  ? err.message || t("Error")
                  : err || t("Error"),
            },
          ]);

          const error = new Error(
            `message send error: ${config.customerKey} ${senderUserId}`
          );
          Object.assign(error, {
            error: err,
          });
          Sentry.captureException(error);

          // only call onFail methods for single-sent messages
          if (contacts.length === 1) {
            onFail && onFail();
          }
        }
      }

      if (data.addReminderForm) {
        quickReminderResource.reloadList();
      }
      resourceHelper.reloadList("events");
      mainMessageResource.reloadList();

      if (
        contactListShouldReload(data.contact, data.contactCC, data.contactBCC)
      ) {
        mainContactResource.reloadList();
      }
    }
  };

  if (sentMessages.length > 1 && messageCount > 1) {
    onDone && onDone(sentMessages);
  }

  return { onSubmit, messageCount, isSending, sendCanceled, sentMessages };
};
