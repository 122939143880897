import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosInstance } from "utilities/axios";
import Config from "config";
import { CasaoneBaseQueryFn } from "./types";
import createApiErrorObject from "@casasoft/styleguide/utilities/api-error/createApiErrorObject";
import { subscribeLegacyResourceHelperMiddleware } from "./legacyResourceHelperMiddleware";
import axios from "axios";

const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string } = { baseUrl: "" }): CasaoneBaseQueryFn =>
  async ({ url, method = "GET", data, params }) => {
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (error) {
      return createApiErrorObject(error);
    }
  };

const axiosQueryWithoutInstance =
  ({ baseUrl }: { baseUrl: string } = { baseUrl: "" }): CasaoneBaseQueryFn =>
  async ({ url, method = "GET", data, params }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (error) {
      return createApiErrorObject(error);
    }
  };

/** Casaone API redux slice */
export const casaoneApi = createApi({
  reducerPath: "casaoneApi",
  tagTypes: [
    "User",
    "Phase",
    "Contact",
    "Property",
    "Publication",
    "Event",
    "PropertyContent",
    "Subsidiary",
    "PropertyTag",
    "PropertyContact",
    "GdprPending",
    "Message",
    "UserSignature",
    "Template",
    "MessagePreview",
    "Media",
    "MediaTag",
    "ServiceCredit",
    "IaziCommonData",
    "ContactTag",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.apiUrl}/${Config.customerKey}/`,
  }),
  endpoints: () => ({}),
});

/** Casaone API2 redux slice */
export const casaoneApi2 = createApi({
  reducerPath: "casaoneApi2",
  tagTypes: ["Gdpr", "Employee", "User", "Company", "Contract"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.api2Url}/company/${Config.customerKey}/`,
  }),
  endpoints: () => ({}),
});

/** Casageo redux slice */
export const casageoApi = createApi({
  reducerPath: "casageoApi",
  tagTypes: ["Region"],
  baseQuery: axiosQueryWithoutInstance({
    baseUrl: `${Config.casageoUrl}/`,
  }),
  endpoints: () => ({}),
});

subscribeLegacyResourceHelperMiddleware();
