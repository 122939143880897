export type NotificationColor =
  | "danger"
  | "warning"
  | "info"
  | "info-100" // very light blue
  | "success";

export const COOKIES_AVAILABLE = [
  "cs_unit_test_cookie",
  "cs_invoice_overdue",
  "cs_phishing_warning",
  "cs_release_contact-merge-modal",
  "cs_last_days_website",
  "cs_sync-button_infrastructure-card",
  "cs_maintenance_2023-11-03",
  "cs_new_messaging_info",
  "cs_technical_issues_warning",
  "cs_technical_issues_warning_30_1",
  "cs_message_recipients_info",
  "cs_maintenance_2024-06-17",
] as const;

export type CookieType = typeof COOKIES_AVAILABLE[number];
