import { TableListItemCol } from "@casasoft/styleguide/components/table-list";
import { useTranslation } from "react-i18next";
import {
  BASE_DATA_PROJECT,
  BASE_DATA_PROPERTY,
  BASE_DATA_UNIT,
} from "utilities/propertyTypes";

interface UseColumnsArgs {
  mini: boolean;
  sortable?: boolean;
  propertyType?: string;
}

function useColumns({ mini, propertyType, sortable }: UseColumnsArgs) {
  const { t } = useTranslation();

  const getLabel = (propertyType?: string) => {
    switch (propertyType) {
      case BASE_DATA_PROPERTY:
        return t("Property");
      case BASE_DATA_UNIT:
        return t("Unit");
      case BASE_DATA_PROJECT:
        return t("Project");
      default:
        return t("Property");
    }
  };
  if (mini || sortable) {
    return [
      {
        key: "excerpt",
        label: t(getLabel(propertyType)),
      },
    ];
  }
  const cols: TableListItemCol[] = [];
  cols.push(
    {
      key: "excerpt",
      label: t("Designation"),
      placeholder: "line4x",
      minWidth: 240,
    },
    {
      key: "address",
      label: t("Address"),
      placeholder: "line4x",
    }
  );
  if (propertyType === BASE_DATA_PROPERTY) {
    cols.push({
      key: "sizes",
      label: t("Size"),
      placeholder: "line4x",
      maxWidth: 300,
    });
  } else {
    cols.push({
      key: "yearBuilt",
      label: t("standards:Year built"),
      placeholder: "line4x",
      maxWidth: 300,
    });
  }
  cols.push({
    key: "price",
    label: t("Price"),
    placeholder: "line4x",
    maxWidth: 300,
    isSortable: true,
  });

  return cols;
}

export default useColumns;
