import { DndContext, useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ReactNode } from "react";

const Droppable = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const { setNodeRef } = useDroppable({ id: "droppable" });

  return (
    <div ref={setNodeRef} className={className}>
      {children}
    </div>
  );
};

interface TableListItemsWrapperProps {
  sortable: boolean;
  cardView: boolean;
  mini: boolean;
  children: ReactNode;
  onSortEnd: (arg: { oldIndex: number; newIndex: number }) => void;
  itemKeys: (string | number)[];
}

function TableListItemsWrapper({
  sortable,
  cardView,
  mini,
  children,
  onSortEnd,
  itemKeys,
}: TableListItemsWrapperProps) {
  const wrapperClassNames = `tw-relative tw-overflow-x-auto ${
    !mini ? "tw-px-4 tw--mx-4 tw--mt-4" : "tw-px-3 tw--mx-3 tw--mt-3"
  } ${cardView ? "tw-flex tw-flex-wrap" : ""}`;
  return (
    <DndContext
      onDragEnd={async (e) => {
        if (!e.over || e.over.id === e.active.id) {
          return;
        }
        const activeItemIndex = itemKeys.findIndex(
          (key) => key === e.active.id
        );
        const overItemIndex = itemKeys.findIndex((key) => key === e.over?.id);

        if (overItemIndex === -1) return; // some cases where the overItem is the Droppable container-id for example
        onSortEnd?.({
          oldIndex: activeItemIndex,
          newIndex: overItemIndex,
        });
      }}
    >
      <SortableContext
        strategy={cardView ? undefined : verticalListSortingStrategy}
        items={itemKeys}
        disabled={!sortable}
      >
        <Droppable className={`${wrapperClassNames}`}>{children}</Droppable>
      </SortableContext>
    </DndContext>
  );
}

export default TableListItemsWrapper;
