import resourceGrabber from "utilities/resourceGrabber";
import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";
import { categoriesList, legacyCategoriesList } from "./categoriesList";

const untranslatedList = categoriesList.map(([value, label]) => ({
  value,
  label,
}));
const legacyUntranslatedList = legacyCategoriesList.map(([value, label]) => ({
  value,
  label,
}));

export const getCategories = (...args: any) => {
  // TODO remove feature-flag, turn function into 1-liner again
  const isDev =
    resourceGrabber.grab("profile", "auth")?.data?.company?.id === "dev";
  const list = isDev ? untranslatedList : legacyUntranslatedList;
  return createGetList(list)(...args);
};

export const getLabel = (category: string) => {
  // TODO remove feature-flag, turn function into 1-liner again
  const isDev =
    resourceGrabber.grab("profile", "auth")?.data?.company?.id === "dev";
  const list = isDev ? untranslatedList : legacyUntranslatedList;
  return createGetLabel(list)(category);
};

/** gets a joined string list of untranslatedList */
export function getLabelListFromEntity(entity?: {
  _embedded?: {
    categories?: {
      id?: number;
      slug: string;
      rank: number;
    }[];
  };
}) {
  if (entity?._embedded?.categories?.length) {
    const items = entity._embedded.categories.map((elem) =>
      getLabel(elem.slug)
    );
    return items.join(", ");
  }
  return "";
}

export const useCategories = (...args: any) => {
  // TODO remove feature-flag, turn function into 1-liner again
  const isDev =
    resourceGrabber.grab("profile", "auth")?.data?.company?.id === "dev";
  const list = isDev ? untranslatedList : legacyUntranslatedList;
  return createUtilityHook(list)(...args);
};
